.hero-banner img {
  height: 750px;
}

/***SEAT LAYOUT PAGE***/
.container-seat-spacing {
  padding: 0 150px !important;
}
.second-table.seat-alphabets {
  /* width: 30%; */
}

.seat-alphabets td {
  font-weight: bold;
}

/* .seats .cell:hover {
  background-image: url("../pictures/svgs/seat-selected.svg");
} */



/* .seats .cell.inactive {
  cursor: not-allowed;
  background-color: rgba(250, 250, 250, 0.1);
  color: rgba(216, 216, 216, 0.8);
} */



.seatlayout__order {
  background-color: #e4e4e4;
  /* height: 100vh;
  overflow-y: auto; */
}
.seatlayout__order-heading {
  font-weight: bold;
  text-align: center;
}
.seatlayout__order-cards-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.seatlayout__order-card {
  display: grid;
  grid-template-columns: 35% 65%;
  /* border: 2px solid rgba(255, 255, 255, 0.2); */
  /* border-radius: 5px; */
  padding: 0px 5px;
  margin-bottom: 10px;
  position: relative;
}
.seatlayout__order-card .dropdown-item {
  padding: 10px 10px 05px 10px;
}
.seatlayout__order-card .dropdown-toggle {
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff !important;
}

.seatlayout__order-card-heading {
  margin-bottom: 1px;
  font-size: 14px;
  letter-spacing: 1.5px;
  display: flex;
  /* align-items: center; */
}
.seatlayout__order-card-text {
  opacity: 0.4;
  font-size: 12px;
}
.seatlayout__order-card-remove {
  position: absolute;
  right: -10px;
  top: -12px;
  cursor: pointer;
}
.seatlayout__order-card-remove .icon-18 {
  width: 08px;
  height: 08px;
}
.seatlayout__order-card-remove > div {
  display: flex;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #000;
  border-radius: 50%;
}
.seatlayout__order.mobile-class {
  display: none;
}
.seatlayout__order.mobile-class.showpopup {
  display: block;
  position: absolute;
  top: -70px;
  height: 125vh;
}
.seat-order-accordion.card {
  background: transparent !important;
}
.seat-order-accordion {
  background: transparent !important;
}
/* .seat-order-accordion .card-body {
  padding: 1rem 0.5rem 1rem 0.5rem !important;
  border: 1px solid #2b2b2b !important;
  background: rgba(0, 0, 0, 0.4) !important;
} */
.seat-order-accordion .card-header {
  margin-bottom: 0px !important;
}
.seatlayout__order--movie-details {
  padding: 1rem 0.5rem 1rem 0.5rem !important;
  border: 1px solid #2b2b2b !important;
}
.seatlayout__order--footer {
  position: absolute;
  bottom: 20px;
  width: 92%;
}
.seatlayout__bottom-space {
  height: 210px;
}


/****SELECT COUNTRY PAGE***/
.selectcountryfooter {
  background: #fff !important;
  color: hsl(0, 0%, 47%) !important;
  display: flex;
  justify-content: space-between;
  margin: 0px 85px;
}

.selectcountryfootericon {
  gap: 2rem;
  display: flex;
  font-size: 20px;
  justify-content: flex-end;
}

.selectcountry {
  background: #cac5c5;
}

.selectcountryfooter {
  background: #fff !important;
  color: hsl(0, 0%, 47%) !important;
  display: flex;
  justify-content: space-between;
  margin: 0px 85px;
}

.selectcountryfootericon {
  gap: 2rem;
  display: flex;
  font-size: 20px;
}

.selectcountry {
  background: #cac5c5;
}


.movie-details-bg {
  background: #f0f0f0;
}

.grid-dropdown {
  display: grid;
  grid-template-columns: 20% 20% 20%;
}


.grid-dropdown .btn {
  width: 100%;
}

.custom-mobile-navbar .grid-dropdown {
  display: grid;
  grid-template-columns: 80px 120px 120px;
}

.custom-dropdown-1ar .dropdown-menu {
  transform: translate3d(0px, 36px, 0px) !important;
}

.custom-dropdown-1mobar .dropdown-menu {
  right: 0 !important;
}

.custom-dropdown-1ar .dropdown-item {
  text-align: right;
}

.custom-dropdown-1ar img {
  margin-left: 8px;
}

.movie-main-heading {
  /* font-family: 'Raleway', sans-serif; */
}

.footer-copyright {
  color: var(--grey-2);
}
.select-country-section .cinepolis-logo {
  width: 225px;
}
.exp-logo img {
  width: 150px;
}
.search-input-field {
  position: relative;
}
.search-input-field figure {
  position: absolute;
  top: 05px;
  right: 15px;
}
.search-input-field .form-control {
  border: 1px solid var(--grey);
  box-shadow: -1px 1px 3px 0px grey;
}
.custom-dropdown-item {
  color: #fff !important;
}
/****OFFERS PAGE*****/
.go-back {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
}
.go-back img {
  width: 14px;
  height: 14px;
}
.time-left-section {
  font-size: 15px;
}


@media (max-width: 1440px) {
  .hero-banner img {
    height: 575px !important;
  }
}


.btn-gray {
  background: var(--grey) !important;
  color: var(--white) !important;
}
.btn-outline-purple {
  color: var(--purple) !important;
  border-color: var(--purple) !important;
}

.text-purple {
  color: var(--purple);
}

.text-dark-grey {
  color: #151515;
}

.text-grey {
  color: var(--grey-2);
}

.dropdown-grid {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-column-gap: 20px;
}

.dropdown-1 .btn {
  border: 1px solid var(--grey-2);
  background: #c7c7c7;
  height: 40px;
  color: black;
  border-radius: 0px;
  width: 100%;
}

.dropdown-2 .btn {
  border: 1px solid var(--grey-2);
  background: transparent !important;
  height: 40px;
  color: black;
  border-radius: 0px;
  width: 100%;
}

.custom-dropdown-1 .btn {
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  /* width: 150px; */
  border-radius: 0px;
  border: 1px solid linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  font-size: 14px;
  text-align: center !important;
}
.custom-dropdown-1 .dropdown-toggle:after {
  border-top: 0.5em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* right: 0; */
  top: 15px;
  position: absolute;
}
.custom-dropdown-2 .btn {
  background: #e4e4e4;
  /* width: 150px; */
  border-radius: 0px;
  color: var(--grey-2) !important;
  border: 1px solid #e4e4e4 !important;
  font-size: 14px;
  text-align: center !important;
}

.custom-dropdown-item {
  background-color: var(--purple) !important;
}
.custom-dropdown-2 .dropdown-toggle:after {
  border-top: 0.5em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* right: 0; */
  top: 15px;
  left: 135px;
  position: absolute;
}
.custom-dropdown-1 .dropdown-menu,
.custom-dropdown-2 .dropdown-menu {
  background: #ebebeb;
  border: 1px solid #ebebeb !important;
  border-radius: 0px;
  padding: 0 !important;
}
.custom-dropdown-1 .dropdown-item,
.custom-dropdown-2 .dropdown-item {
  border: 1px solid #ebebeb !important;
  text-align: left;
  padding: 5px 15px !important;
}
.custom-dropdown-1 .dropdown-item:hover,
.custom-dropdown-2 .dropdown-item:hover {
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  color: #fff;
}
.dropdown-menu.show {
  transform: translate3d(0px, 35px, 0px) !important;
}
.custom-dropdown-3 .btn {
  width: 100%;
  background: #c7c7c7 !important;
  color: var(--grey);
  border-radius: 0px;
  width: 100%;
  border: 3px solid #c7c7c7 !important;
}
.custom-dropdown-4 {
  border: 1px solid var(--grey) !important;
}
.custom-dropdown-3 .btn {
  width: 100%;
}

.custom-dropdown-4 .btn {
  background: #e4e4e4 !important;
}
.dropdownClassic {
  background: #fff !important;
  color: var(--grey-2) !important;
  border: 1px solid var(--grey-2) !important;
  border-radius: 08px;
}
/* .dropdown-toggle:after {
  content: url("../pictures/svgs/arrow-down-purple.svg") !important;
  position: absolute;
  top: 2px !important;
  right: 1rem;
  height: 100%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none !important;
} */



/****NAVBAR****/
.custom-desktop-navbar {
  background-color: white;
}
.custom-desktop-navbar .header-items {
  padding: 0 !important;
  margin-bottom: 0 !important;
  gap: 35px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.custom-desktop-navbar .header-items a {
  color: var(--grey-2) !important;
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap;
}
.custom-desktop-navbar .custom-dropdown-5 a:hover {
  color: white !important;
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
}
.custom-desktop-navbar .btn-auth {
  padding: 10px 30px;
  letter-spacing: 1px;
  font-size: 14px;
  white-space: nowrap;
}


/***SLICK ARROWS***/
.banner-arrows .slick-prev {
  left: 05px;
  right: unset;
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;

}

.banner-arrows .slick-next {
  right: 05px;
  left: unset;
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229) ) !important;
}
.movie-slider-arrows .slick-prev {
  left: -20px;
  right: unset;
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229) ) !important;
}

.movie-slider-arrows .slick-prev:before,
.movie-slider-arrows .slick-next:before,
.banner-arrows .slick-prev:before,
.banner-arrows .slick-next:before {
  content: "";
  right: 0%;
  display: block;
  z-index: 111;
  background-repeat: no-repeat !important;
  border-radius: 0;
  box-shadow: none;
  width: 30px;
  height: 30px;
  background-size: 30px !important;
  background: url("../pictures/slick-arrows.svg");
}
.movie-slider-arrows .slick-next:before,
.banner-arrows .slick-next:before {
  transform: rotate(180deg);
  margin-left: 6px;
}

.movie-slider-arrows .slick-next {
  right: -20px;
  left: unset;
  /* background: url("../pictures/slick-arrows.svg") */
}

/***ACCORDIONS****/
.custom-accordion-1 .card {
  background: transparent !important;
}
.custom-accordion-1 .card-header {
  background: transparent !important;
  border-top: 1px solid #d4d4d4 !important;
  border-bottom: 1px solid #d4d4d4 !important;
  padding: 10px 0 !important;
}
/* .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: transparent !important; 
  background-color: transparent !important; 
   border-color: transparent !important;
} */
.show > .btn-primary.dropdown-toggle,
.btn-primary:focus {
  color: var(--grey) !important;
  /* background-color: transparent !important; */
  border-color: var(--grey) !important;
}
.btn-primary:hover {
  color: inherit !important;
  background-color: inherit !important;
}
.custom-modal-1 {
  background: white !important;
  border-radius: 6px;
}

.custom-form-control {
  border: 1px solid var(--grey) !important;
}
.custom-dropdown-5 .btn {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: var(--grey) !important;
}
.custom-dropdown-5 .dropdown-toggle::after {
  display: none !important;
}
.custom-dropdown-5 .btn > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom-dropdown-5 .btn span {
  margin-top: 3px;
}
.custom-dropdown-5 .dropdown-menu {
  background: #ebebeb !important;
  border-radius: 0px;
  border: 1px solid #ebebeb !important;
  text-align: center;
  width: 100%;
}
.custom-dropdown-5 .dropdown-menu.show {
  transform: translate3d(0px, 42px, 0px) !important;
}
.custom-dropdown-5 .dropdown-item {
  padding: 05px;
}
.custom-dropdown-5 .dropdown-item:hover {
  color: #fff !important;
}
.show > .btn-primary.dropdown-toggle,
.btn-primary:focus {
  border: 1px solid #797979 !important;
}
.home-quickbook .dropdown .btn:focus {
  /* border: 1px solid var(--grey) !important; */
  font-size: 15px;
  width: 100%;
  background: #fff0 !important;
  color: #797979 !important;
  border: 1px solid #797979 !important;
  height: 50px !important;
  box-shadow: -1px 1px 3px 0 #9F9F9F !important;
}


.offer-card-number .form-control {
  border: 1px solid var(--grey);
  background: transparent !important;
  border-radius: 0px;
  height: 35px;
}
.offer-card-number .btn-offer {
  height: 35px;
}

/* Time Image */

.timeline-img {
  width: 30px;
  height: 30px;
}

.timeline-img-unactive {
  filter: invert(100%);
}

/* header input*/

.typo-li-type {
  margin-top: 2;
  color: rgb(0, 0, 0);
  text-align: left;
  border: 1px solid grey;
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
  max-height: 250px;
  overflow-y: scroll;
}

.list-inline-item p {
  white-space: normal;
  padding: 3px !important;
}

.list-inline-item p:hover {
  background-color:  rgb(71,129,255);
  transition: 0.2s ease-in-out;
}

.movie-details-container {
  line-height: 1.8;
  font-size: 16px;
}


.movie-details-runtime-rating {
  background-color:#919191;
  border-radius: 50px;
  max-width: 100px;
  text-align: center;
  padding: 7px 7px 7px 7px;
  font-size: 12px;
  margin-bottom: 6px;
}

.flow-timeline-header {
  background-color: #fff;
}

.fnb-section-bg {
  background-color: #fff;
}

.skip-proceed-mobile {
  border: 1px solid gainsboro;
  padding: 2px !important;
  border-radius: 5px;
  background: whitesmoke;
  font-size: 12px;
}

.checkout-section-bg {
  background-color: #fff;
}

.alert-error-msg-seat {
  width: 65% !important;
}
.alert-error-msg-seat .alert-heading {
  font-size: 18px !important;
  margin-bottom: 0rem;
}
.alert-error-msg-seat p {
  font-size: 14px !important;
}
.alert-error-msg-seat .alert{
  padding: 0.3rem 1.25rem !important;
  margin-bottom: 0rem !important;
}
.review-order-img {
  height: 130px;
  width: 100px;
}

.checkout-payment-input {
  /* width: 70%; */
}
.checkout-payment-input input{
  font-size: 16px !important;
  border: none !important;
  padding: 0px 0px 0px 5px !important;
  color: var(--grey-2) !important;
}
.checkout-payment-input:first-child .label1 {
  width: 35% !important;
}
.checkout-payment-input:last-child .label2 {
  width: 55% !important;
}

.checkout-label {
  align-items: baseline !important;
  height: 22px;
  color: var(--grey-2) !important;
}
.cart-items-scroll {
  max-height: 100px;
  overflow-y:auto;
}
.bg-section-order-detals {
  height: 175px !important;
  overflow-y: auto;
}
.font-size-small {
  font-size: 12px;
}
.dropdown-country-change-flag {
  width: 25px;
  margin-right: 10px;
}
.dropdown-country-alignment {
  display: flex !important;
  justify-content: space-between !important;
  width: 160px;
}
.dropdown-language-alignment {
  min-width: 100% !important;
}
[dir="rtl"] .dropdown-country-alignment .dropdown-item {
  text-align: right !important;
}

.luxury-main-container {
  background-color: #F1F1F1;
  padding: 50px 50px 50px 50px;
}
.luxury-data-div {
  color: var(--grey-2);
  font-size: 16px;
}

.modal-body-checkout-tnc {
  height: 350px;
  overflow-y: scroll;
}
.modal-content-checkout-tnc .modal-content {
  background-color: #fff !important;
  top: 60px;
  width: 100%;
  margin:auto;
  font-size: 14px;
}

.modal-content-checkout-tnc .card-body {
  padding: 15px;
}
.modal-content-checkout-tnc .card-header {
  /* padding: 5px 15px; */
}
.modal-content-checkout-tnc .card-header::after {
  /* top: 8px; */
  right: 7px;
  height: 16px;
  width: 16px;
}
.arrows-none .slick-arrow,.arrows-none .slick-next, .arrows-none .slick-prev {
  display: none !important;
}
.loc-txt-accordion {
  font-size: 12px;
}
.text-small1 {
  font-size: 13px;
  color: rgba(160, 160, 160, 1);
}
.img-fluid-100 {
  height: 100%;
  width: 100%;
}
.about-slider-index {
  /* z-index: -1; */
}

.exp-scroll-slides::-webkit-scrollbar {
  height: 12px;
  z-index: 10000;
  margin-top: 10px;
} 

.name-input-review-order input{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.review-name-check-p {
  border-bottom: 1px solid rgba(160, 160, 160, 1);
  color: rgba(160, 160, 160, 1);
  font-size: 14px;
  padding-bottom: 5px !important;
}

.forget-align-end {
  text-align: end;
}
.quick-book-day-split {
  width: 40px;
}
.quick-book-month-split {
  width: 80px;
}
.quick-book-date-split {
  width: 32px;
  text-align: end;
}
.inner-quickbook-date .dropdown-item {
  padding: 0.25rem 0.8rem !important;
}

.hr-line-movie h6 {
  font-family: sans-serif;
  margin: 100px auto;
  color: var(--grey-2);
  text-align: center;
  font-size: 16px;
  max-width: 310px;
  position: relative;
  padding: 0px 100px 0px 100px !important;
}
.hr-line-movie h6:before {
  content: "";
  display: block;
  width: 85px;
  height: 2px;
  background: var(--grey-2);
  left: 0;
  top: 50%;
  position: absolute;
}
.hr-line-movie h6:after {
  content: "";
  display: block;
  width: 85px;
  height: 2px;
  background: var(--grey-2);
  right: 0;
  top: 50%;
  position: absolute;
}

.hr-no-margin {
  margin-top: 8px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 992px) {
  .custom-desktop-navbar .header-items {
    gap: 15px;
  }
  .custom-desktop-navbar .btn-auth {
    padding: 5px 12px;
  }

}


@media only screen and (max-width: 768px) {
  .movie-details-container {
    line-height: 1.8;
    font-size: 15px;
  }
  .movie-details-container > div {
    display: grid;
    grid-template-columns: 35% 70%;
  }
  
  .modal-content-checkout-tnc .card-body {
    padding: 15px;
  }
  .modal-content-checkout-tnc .card-header {
    padding: 5px 15px;
  }
  .modal-content-checkout-tnc .card-header::after {
    top: 8px;
    right: 7px;
    height: 16px;
    width: 16px;
  }
  .modal-body-checkout-tnc {
    height: 300px;
    overflow-y: scroll;
  }
  .modal-content-checkout-tnc .modal-content {
    background-color: #fff !important;
    top:100px;
    width: 85%;
    margin:auto;
    font-size: 12px;
  }
  .promotion-card .btn-details {
    margin-top: 15px !important;
  }
  .custom-desktop-navbar .header-items {
    gap: 5px;
  }
}

.navbar-max-width {
  max-width: 1920px;
}

/*Fnb Container*/
@media only screen and (max-width: 1920px) {
  .fnb-card-item img {
    height: 130px !important;
  }
  .fnb-li-items {
    overflow-x: auto;
    overflow-y: hidden;
    width: 135%;
  }
  .fnb-order-details{
    margin-top: 28% !important;
  }

  .navbar-max-width {
    max-width: 1720px;
  }
}

@media only screen and (max-width: 1370px) {
  .fnb-card-item img {
    height: 120px !important;
  }
  .fnb-order-details{
    margin-top: 31% !important;
  }
  .navbar-max-width {
    max-width: 1320px;
  }
}

@media only screen and (max-width: 1140px) {
  .fnb-card-item img {
    height: 100px !important;
  }
  .fnb-order-details{
    margin-top: 35% !important;
  }
}

@media only screen and (max-width: 992px) {
  .fnb-order-details {
    margin-top: 20px !important;
    position: static !important;
  }
  .fnb-li-items {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    margin-bottom: 20px !important;
  }
  .seat-order-details {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) {

  .checkout-payment-input:first-child .label1 {
    width: 20% !important;
  }
  .checkout-payment-input:last-child .label2 {
    width: 20% !important;
  }
  .fnb-li-items-mobile {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
    grid-gap: 20px;
    gap: 5px;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 12px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .fnb-li-items-mobile li{
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    color: var(--grey-2);
  }
  .fnb-li-items-mobile .active ,.fnb-li-items-mobile li:hover{
    color: var(--purple);
    font-family: "Raleway-SemiBold";
    transform: scale(1.1);
    transition: 0.1s;
    border-bottom: 3px solid var(--purple);
  }
  .mobile-container-final-ticket {
    width: 95%;
    margin: auto;
  }
  .summary-page-main-text {
    font-size: 16px !important;
  }
  .final-ticket-movie-detail {
    background-color: var(--grey-3);
    padding: 15px;
    font-size: 12px;
    color: var(--grey-2);
  }
  .summary-movie-div {
    height: 100px !important;
    width: 80px !important;
  }
  .qr-code-mobile {
    height: 80px !important;
    width: 80px !important;
    background-color: skyblue;
  }
  .qr-code-mobile-div {
    width: 110px;
    font-size: 8px;
  }
  .hr-final {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 450px) {
  .hr-line-movie h6 {
    font-family: sans-serif;
    margin: 100px auto;
    color: var(--grey-2);
    text-align: center;
    font-size: 12px;
    max-width: 283px;
    position: relative;
    padding: 0px 80px 0px 80px !important;
  }
  .hr-line-movie h6:before {
    content: "";
    display: block;
    width: 80px;
    height: 2px;
    background: var(--grey-2);
    left: 0;
    top: 50%;
    position: absolute;
  }
  .hr-line-movie h6:after {
    content: "";
    display: block;
    width: 80px;
    height: 2px;
    background: var(--grey-2);
    right: 0;
    top: 50%;
    position: absolute;
  }
  .review-name-check-p {
    font-size: 12px;
  }
  .luxury-main-container {
    background-color: #F1F1F1;
    padding: 20px 20px 20px 20px;
  }
  .luxury-data-div {
    width: 50%;
    font-size: 10px;
  }
  .lux-main-txt {
    font-size: 16px;
    font-weight: bold;
  }
  .fnb-figure {
    height: 60px;
  }
  .fnb-card-item img {
    height: 80px !important;
  }
  .fnb-card-item .fnb-name {
    font-size: 10px !important;
    padding-top: 8px !important;
    height: 40px !important;
  }
  .fnb-price {
    font-size: 10px !important;
  }
  .fnb-card-item {
    width: 88%;
    margin: auto;
  }

/*seat layout media query*/
  .seat-layout-desc {
    gap:10px !important;
  }
  .seat-layout-desc figure img {
    height: 15px !important;
    width: 15px !important;
  }
  .seat-layout-desc span {
    font-size: 12px !important;
  }
  .seats .cell {
    background-size: 15px !important;
  }
  .cell {
    width: 12px !important;
    height: 25px !important;
  }
  .sofa, .double-bean {
    width: 30px !important;
    height: 25px !important;
    background-size: 30px !important;
  }
  .wheel-chair {
    background-size: 20px !important;
  }

  /*offer Container*/
  .text-offer {
    font-size: 12px !important;
  }

  /*fnb mobile view*/
  .seat-order-details .btn-proceed {
    margin-top: 0% !important;
    font-size: 10px !important;
    font-weight: bold;
    min-width: 120px;
  }
  .choose-item-txt {
    font-size: 15px;
  }
  .down-arrow-color {
    filter: invert(50%);
  }
  .food-drink-font, .fnb-apply-btn {
    font-size: 13px !important;
    min-width: 120px;
  }

  .fnb-mobile-modal-details, .fnb-sub-item {
    line-height: 1;
    font-size: 12px;
  }
  .fnb-item-qty span, .fnb-qty-modal-btn button{
    font-size: 12px;
  }
  .fnb-item-qty .btn {
    padding: 0px;
  }

  /*checkout Page*/
  .checkout-mobile-text {
    font-size: 20px !important;
  }
  .checkout-payment-input {
    width: 70%;
  }
  .checkout-payment-input input{
    font-size: 12px !important;
    border: none !important;
    padding: 0px 0px 0px 5px !important;
  }
  .checkout-payment-input label {
    font-size: 12px !important;
    width: 45% !important;
  }
  .checkout-payment-input:first-child .label1 {
    width: 45% !important;
  }
  .checkout-payment-input:last-child .label2 {
    width: 45% !important;
  }
  .checkout-label {
    align-items: baseline !important;
    height: 22px;
  }
  .payment-page .grid-1 {
    display: block !important;
    font-size: 12px !important;
    margin-bottom: 40px !important;
  }
  .payment-page .grid-1 > div:not(:last-child) {
    border-right:  none !important;
  }
  .payment-page .btn {
    font-size: 12px;
    margin-top: 15px;
    width: 100% !important;
  }
  .payment-proceed-div {
    width: 95%;
    margin: auto;
  }
  .payment-page .form-1 {
    margin-bottom: 20px !important;
  }

  /*fnb container*/
  .fnb-li-items-mobile {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
    grid-gap: 20px;
    gap: 5px;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 12px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .heading-text-mobile h2{
    font-size: 16px !important;
  }
  .signup-input-inline {
    padding-left: 5px;
    padding-right: 5px;
  }
  .signup-sticky, .proceed-review-order-btn {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px;
  }
  .payment-card-section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 14px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 15px;
  }


  /* Final Ticket Mobile */
  .mobile-container-final-ticket {
    width: 95%;
    margin: auto;
  }
  .summary-page-main-text {
    font-size: 16px !important;
  }
  .final-ticket-movie-detail {
    background-color: var(--grey-3);
    padding: 15px;
    font-size: 12px;
    color: var(--grey-2);
  }
  .summary-movie-div {
    height: 100px !important;
    width: 80px !important;
  }
  .qr-code-mobile {
    height: 80px !important;
    width: 80px !important;
    background-color: skyblue;
  }
  .qr-code-mobile-div {
    width: 110px;
    font-size: 8px;
  }
  .hr-final {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .rev-div-mobile-align .form-control {
    height: 15px !important;
  }
  .payment-new-page .form-control {
    padding: 8px 0px 14px 0px !important;
  }

  .mobile-dropdown-code .dropdown-item {
    font-size: 10px !important;
    padding: 0.2rem 1rem;
  }

   .mobile-number-code .dropdown-item {
    font-size: 10px !important;
    padding: 0.2rem 0.4rem;
  }

}

.mobile-seat-order-details, .mobile-movie-seat-order-details {
  width: 100% !important;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: var(--grey-3);
  color: var(--grey);
  font-family: "Raleway-Bold";
  width: 100%;
  font-size: 12px;
  border-radius: 6px;
}

.fnb-mobile-hr {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.total-mobile-text {
  font-size: 13px !important;
}

/*Error Popup New*/
.modal-cross {
  cursor: pointer;
  background-color: #fff;
  border: none;
  font-size: 25px;
}
.modal-popup-subtitle {
  font-size: 20px;
  font-weight: 800;
  margin-top: 8px !important;
  margin-bottom: 25px !important;
}
.modal-popup-logo {
  height: 120px;
  width: 120px;
}
.modal-popup-title {
  color: var(--purple);
  -webkit-text-stroke: 4px solid #000 !important;
  font-size: 25px !important;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  letter-spacing: 2.2px;
}

.loginactive {
  border-bottom: 3px solid var(--purple);
  padding-bottom: 4px;
  color: var(--grey-2);
}
.loginunactive {
  border-bottom: none;
  color: #000;
  opacity: 0.4;
  font-weight: 400;
}
.loginunactive:hover {
  border-bottom: 3px solid var(--purple);
  padding-bottom: 4px;
  color: var(--grey-2);
  transition: 0.2s;
  font-weight: 600;
  opacity: 1;
}

.login-text-check {
  color: #857D7D !important;
}

.promo-code-alert .h4{
  font-size: 12px !important;
  margin-bottom: 0px !important;
}
.promo-code-alert .alert {
  font-size: 12px !important;
  padding: 8px 8px !important;
}
.promo-code-alert .alert-dismissible .close {
  padding: 3px 10px !important;
}

@media (max-width: 450px) {
  .promo-code-alert .h4 {
    font-size: 10px !important;
  }
  .promo-code-alert .alert-dismissible .close {
    padding: 0px 8px !important;
  }
  .movie-title-bold {
    font-size: 1.1rem !important;
  }

  
.custom-dropdown-1 .btn {
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  border-radius: 0px;
  border: 1px solid linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  font-size: 10px;
  text-align: center !important;
  padding: 0.2rem 0.5rem;
  height: 26px !important;
}

.custom-dropdown-1 .dropdown-toggle:after {
  border-top: 0.5em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* right: 0; */
  top: 10px;
  position: absolute;
}

.custom-dropdown-2 .btn {
  background: #e4e4e4;
  border-radius: 0px;
  color: var(--grey-2) !important;
  border: 1px solid #e4e4e4 !important;
  font-size: 10px !important;
  text-align: center !important;
  height: 26px;
  padding: 0.20rem 0.5rem;

}
.custom-dropdown-2 .dropdown-toggle:after {
  border-top: 0.5em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* right: 0; */
  top: 10px;
  left: 90px;
  position: absolute;
}

.custom-dropdown-2 .dropdown-toggle::after {
  content: "";
  display: block;
  /* background-image: url(/_next/static/media/back-arrow.37398e3e.svg); */
  right: 0px;
  background-size: 15px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  border: none !important;
  transform: rotate(-90deg);
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.5em solid;
  left: 135px;
  position: absolute;
  top: 0px;
}

.dropdown-country-alignment {
  width: 120px;
}
.dropdown-menu.show {
  transform: translate3d(0px, 25px, 0px) !important;
}
.custom-dropdown-1 .dropdown-item, .custom-dropdown-2 .dropdown-item {
  padding: 0.2rem 0.5rem !important;
  font-size: 10px;
}
.dropdown-menu {
  min-width: 100% !important;
}

.feedback-title-mobile {
  font-size: 16px !important;
}
.feedback-txt-mobile {
  font-size: 12px;
  padding: 5px 0px 10px 0px !important;
}

.bg-section-order-detals {
  height: auto !important;
}

.banner-sticky-mobile {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  z-index: 1000;
  top: 89px;
  background-color: #ffffff;
}
.movie-nav-tab-sticky .movie-list-tabs{
  position: sticky;
  z-index: 999;
  top: 290px;
  background-color: #ffffff;
  padding: 15px 0px 5px 0px;
}
.alert-error-msg-seat {
  width: 100% !important;
}
.alert-error-msg-seat p {
  font-size: 12px !important;
}
.text-title-mobile {
  font-size: 16px !important;
  font-weight: bold;
}
.text-content-mobile {
  font-size: 12px !important;
}
.promotion-content, .custom-form-1 , .text-grey{
  font-size: 12px !important;
}
.text-small-mobile .nav-tabs{
  font-size: 12px !important;
}
.btn {
  font-size: 10px !important;
}

.btn-offer-1,
.btn-apply-1,
.btn-proceed-1,
.btn-apply,
.btn-offer-2,
.btn-proceed {
  font-size: 10px !important;
}

.payment-new-page .flex-1 label {
  font-size: 12px !important;
}
.payment-new-page .fs-14 , .text-small1, .payment-new-page .grid-2{
  font-size: 12px !important;
}

.fnb-mobile-scroll-view {
  padding-top: 10px;
}

.offer-details {
  font-size: 12px;
  line-height: 18px;
}
.offer-name-heading {
  font-size: 14px;
}
.sm-text {
  font-size: 12px !important;
}
.faq-tab {
  font-size: 14px;
  height: 40px !important;
}

.faq-custom-accordion .card-header, .faq-custom-accordion .card-body{
  font-size: 12px !important;
}

.custom-accordion-3 .card-header.hidden:before {
  top: 21%;
}
.custom-accordion-3 .card-header:after {
  top: 12px;
}
.review-tnc-modal-div .card-header:after{
  top: 8px !important;
} 
.review-tnc-modal-div .card-header.hidden:before{
  width: 22px;
  height: 22px;
  top: 9%;
  right: 4px !important;
}
.modal-header-tnc-review {
  padding: 1rem 1rem 0rem 1rem !important;
}

.sticky-top-fnb-timeline-mobile {
  position: sticky;
  top: 89px;
  z-index: 1;
}
.sticky-top-fnb-timeline-img-mobile {
  position: sticky;
  top: 130px;
  z-index: 1;
}
.sticky-top-fnb-tabs-mobile {
  position: sticky;
  top: 225px;
  z-index: 1;
  background-color: #fff;
}
.close-btn-tnc {
  letter-spacing: 1px !important;
}
.alert-error-msg-seat .alert-heading {
  font-size: 16px !important;
}
.review-movie-grid {
  grid-template-columns: 20% 50%;
  display: flex;
}
.review-movie-grid-img {
  width: 21%;
}
.review-movie-grid-content {
  margin-left: 20px;
}


}

@media (max-width: 415px) {
  .movie-nav-tab-sticky .movie-list-tabs{
    top: 275px;
  }
}

@media (max-width: 405px) {
  .movie-nav-tab-sticky .movie-list-tabs{
    top: 275px;
    padding: 10px 0px 5px 0px;
  }
}

@media (max-width: 385px) {
  .movie-nav-tab-sticky .movie-list-tabs{
    top: 270px;
  }
}

@media (max-width: 375px) {
  .movie-nav-tab-sticky .movie-list-tabs{
    top: 265px;
  }
}

@media (max-width: 365px) {
  .movie-nav-tab-sticky .movie-list-tabs{
    top: 260px;
  }
}

@media (max-width: 355px) {
  .movie-nav-tab-sticky .movie-list-tabs{
    top: 255px;
  }
}

@media screen and (max-width: 800px), 
       screen and (max-height: 600px) {
  
}
.apply-Pay-Button {
  width: 100% !important;
  background-color: #000 !important;
  cursor: pointer !important;
  color: white !important;
  border: 1px solid black !important;
  font-size: 14px !important;
  border-radius: 3px !important;
}

.modal-duotix-cart .modal-header{
  padding: 0px;
}

.duotix-counter {
  font-size: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.duotix-counter p{
  width: 45px !important;
  text-align: center;
}

.duotix-counter button{
  padding: 4px 12px;
  border: 1px solid #c7c7c7;
}

.duotix-check-img {
  height: 75px !important;
}

/* .custom-accordion-tabs .card-body-main {
  padding: 0 500px 0 30px !important;
} */

.custom-accordion-tabs-sub .card-header::before {
  left: 0px;
  width: 50%;
}

.custom-accordion-tabs-sub .card-header:after {
  right: auto;
  left: 51%;
}

.custom-accordion-tabs .card-header-sub span{
  font-size: 16px !important;
}

.custom-accordion-tabs .card-header-sub.show:after {
  transform: rotate(0deg);
}

.custom-accordion-tabs .card-header-sub.hidden:after {
  transform: rotate(0deg);
}

.book-concession-cinema-add {
  height: 60px;
}

@media (max-width: 450px) {
  .custom-accordion-tabs .card-header-sub span{
    font-size: 12px !important;
  }
  /* .custom-accordion-tabs .card-body-main {
    padding: 0 60px 0 30px !important;
  } */
  .custom-accordion-tabs-sub .card-header::before {
    left: 0px;
    width: 85%;
  }
  
  .custom-accordion-tabs-sub .card-header:after {
    right: auto;
    left: 85%;
  }
}


.movie-new-card {
  position: relative;
  cursor: pointer;
  padding: 3%;
  font-family: "Kumbh Sans";
}
.movie-new-card:hover .movie-card-content {
  bottom: 6px;
  opacity: 1;
}
.movie-card-content .btn {
  font-size: 13px;
  padding: 0.3rem 0 0.2rem 0;
}
.movie-card-content {
  position: absolute;
  opacity: 0;
  bottom: 0;
  width: 100%;
  /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(16%, rgba(0, 0, 0, 0)), color-stop(93%, #000000));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 16%, #000000 93%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 16%, #000000 93%); */
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.6s;
  bottom: 6px;
  /* padding: 15px; */
  color: #fff;
  padding: 15px 15px 10px 15px;
}
.movie-card-content h6 {
  max-height: 22px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 10px;
}
.movie-content-span {
  font-size: 12px;
  padding-bottom: 05px;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  .movie-new-card .movie-card-content {
    bottom: 6px;
    opacity: 1;
  }
  .movie-slider-arrows {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .movie-card-content {
    bottom: 0px;
    padding: 10px 10px 0px 10px;
  }
  .movie-card-content .btn {
    font-size: 0.8rem;
    display: none;
  }
}

.mr-auto-1 {
  margin: auto !important;
  width: 100% !important;
}
.zero-magin {
  margin: auto;
}
.movie-title-heading {
  font-size: 1.8rem !important;
}
.movie-card {
  position: relative;
  cursor: pointer;
  padding: 3%;
  font-family: "Kumbh Sans";
}
.movie-card:hover .movie-card-content {
  bottom: 6px;
  opacity: 1;
}
.movie-card-content .btn {
  font-size: 13px;
  padding: 0.3rem 0 0.2rem 0;
}
@media (min-width: 768px) {
  .movie-card-content {
    position: absolute;
    opacity: 0;
    bottom: 0;
    width: 94% !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(16%, rgba(0, 0, 0, 0)), color-stop(93%, #000000));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 16%, #000000 93%);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 16%, #000000 93%);
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.6s;
    bottom: 6px;
    color: #fff;
    padding: 15px 15px 10px 15px;
  }
}
@media (max-width: 768px) {
  .movie-card-content {
    position: absolute;
    opacity: 0;
    bottom: 0;
    width: 94% !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(16%, rgba(0, 0, 0, 0)), color-stop(93%, #000000));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 16%, #000000 93%);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 16%, #000000 93%);
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.6s;
    bottom: 6px;
    color: #fff;
    padding: 15px 15px 10px 15px;
  }
}
.movie-card-content h6 {
  max-height: 22px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 10px;
}
.movie-content-span {
  font-size: 12px;
  padding-bottom: 05px;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  .movie-card .movie-card-content {
    bottom: 8px;
    opacity: 1;
  }
  .movie-slider-arrows {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .movie-card-content .btn {
    font-size: 0.8rem;
    display: none;
  }
}

