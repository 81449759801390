/****HOME PAGE****/
.view-more-text a {
  color: var(--grey);
  cursor: pointer;
}

.view-more-text a:hover {
  color: var(--grey);
  cursor: pointer;
  text-decoration: none;
}

.mobile-movie-tabs {
  display: flex;
  justify-content: space-between;
}

/****QUICKBOOK****/
.home-quickbook .dropdown .btn {
  /* font-size: 15px;
  width: 100%;
  background: transparent;
  color: var(--grey);
  border: 1px solid var(--grey);
  height: 50px !important;
  /* line-height: 40px; */
  /* box-shadow: -1px 1px 3px 0px grey; */
  font-size: 15px;
  width: 100%;
  background: #fff0;
  color: #797979;
  border: 1px solid #797979;
  height: 50px !important;
  box-shadow: -1px 1px 3px 0 #9f9f9f;
}

.home-quickbook .dropdown-toggle:after {
  border-top: 0.5em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  right: 9px !important;
  top: 20px;
  position: absolute;
}

.home-quickbook .btn-quickbook {
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1200px) {
  .home-quickbook {
    display: grid !important;
    grid-template-columns: repeat(5, 19%);
    grid-column-gap: 1%;
    width: 80%;
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .home-quickbook {
    display: grid !important;
    grid-template-columns: repeat(5, 19%);
    grid-column-gap: 1%;
    width: 95%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .title-heading {
    font-size: 1.5rem;
  }

  /* .home-quickbook {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 95%;
    margin: 0 auto;
  } */
}

/***MOVIE CARD***/
.movie-new-card {
  position: relative;
  cursor: pointer;
  padding: 3%;
  font-family: "Kumbh Sans";
}

.movie-new-card:hover .movie-card-content {
  bottom: 6px;
  opacity: 1;
}

.movie-card-content .btn {
  font-size: 13px;
  padding: 0.3rem 0 0.2rem 0;
}

.movie-card-content {
  position: absolute;
  opacity: 0;
  bottom: 0;
  width: 94% !important;
  /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(16%, rgba(0, 0, 0, 0)), color-stop(93%, #000000));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 16%, #000000 93%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 16%, #000000 93%); */
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.6s;
  bottom: 6px;
  /* padding: 15px; */
  color: #fff;
  padding: 15px 15px 10px 15px;
}

.movie-card-content h6 {
  max-height: 22px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 10px;
}

.movie-content-span {
  font-size: 12px;
  padding-bottom: 05px;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .movie-new-card .movie-card-content {
    bottom: 6px;
    opacity: 1;
  }

  .movie-slider-arrows {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .movie-card-content {
    bottom: 0px;
    padding: 10px 10px 0px 10px;
  }

  .movie-card-content .btn {
    font-size: 0.8rem;
    display: none;
  }
}

/****BANNER HERO****/

.home-page .banner-hero-content {
  background-image: none !important;
}

.banner-hero {
  position: relative;
  /* height: 450px; */
}

.banner-hero-content {
  position: absolute;
  color: white;
  height: 100%;
  width: 100%;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 2;
  /* padding: 150px 100px 0 100px; */
  background-image: -o-linear-gradient(top,
      rgba(168, 122, 122, 0) 25%,
      #000000 91%);
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(25%, rgba(0, 0, 0, 0)),
      color-stop(99.5%, #000000));
  /* background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 18%, #151525 65%); */
}

.banner-hero-content .content-details {
  position: absolute;
  bottom: 50px;
  padding: 70px 70px 0px 70px;
}

.banner-hero-content .go-back {
  top: 20px;
  position: absolute;
  left: 95px;
}

.banner-hero .span-block {
  background: #c6c6c6;
  border-radius: 5px;
  padding: 8px 20px;
  display: inline-block;
  color: #000;
  font-size: 12px;
}

.details-mobile-content {
  position: absolute !important;
  bottom: 13px;
  width: 100%;
  padding: 2px 20px;
  justify-content: space-between;
  display: flex;
}

.banner-hero .hero-img img {
  width: 100%;
  max-height: 600px;
  /* object-fit: cover; */
}

.banner-hero .movie-span-1 {
  font-size: 15px;
}

.banner-hero .banner-right-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.banner-mobile-content {
  position: absolute;
  bottom: 10px;
  background-image: linear-gradient(180deg, transparent 25%, var(--purple) 91%);
  color: white;
  /* left: 0; */
  /* width: 100%; */
  display: none;
}

.movie-details-runtime-rating {
  background-color: #919191;
  border-radius: 50px;
  max-width: 100px;
  text-align: center;
  padding: 8px 8px 8px 8px;
  font-size: 12px;
  margin-bottom: 6px;
}

@media (max-width: 767px) {
  .banner-hero-content .go-back {
    top: -40px;
    position: absolute;
    left: 0;
  }

  .banner-right-content .movie-name-heading {
    font-size: 22px;
  }

  .banner-hero .hero-img img {
    height: 250px;
  }

  .banner-hero-content {
    padding: 50px 5px;
  }

  .movie-video-trailer {
    position: absolute;
    top: 35%;
    right: 42%;
  }
}

/***MOVIE DETAILS PAGE***/
.choose-date-card {
  transition: background-color 0.4s;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px 0 05px 0;
  text-align: center;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.choose-date-card .day,
.choose-date-card .month {
  color: var(--grey);
  text-transform: uppercase;
  font-size: 13px;
  font-family: "Raleway-SemiBold", sans-serif !important;
}

.choose-date-card .date {
  font-size: 25px;
  padding: 6px 0 3px 0;
  font-family: "Colfax-Regular", sans-serif !important;
}

.selected-date-card {
  background: linear-gradient(rgb(71, 129, 255), rgb(103, 88, 229)) !important;
  color: white !important;
}

.selected-date-card .day,
.selected-date-card .month {
  color: #fff;
}

.showtime-col-border:not(:last-child) {
  border-bottom: 2px solid #dadada;
}

.showtime-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  gap: 04px;
  flex-wrap: wrap;
  font-family: "Colfax-Regular", sans-serif !important;
}

.showtime-list li {
  font-size: 14px;
  color: black;
  padding: 5px 10px;
  /* margin-bottom: 10px; */
  border-radius: 5px;
  white-space: nowrap;
  display: inline-block;
  min-width: 110px;
  text-align: center;
  border: 1.5px solid var(--purple);
  cursor: pointer;
  transition: background-color 0.7s ease-out;
}

.showtime-list li:hover {
  background-color: rgb(71, 129, 255);
  color: #fff !important;
  border: 1.5px solid rgb(71, 129, 255);
}

.movie-details-page .btn-show-movie {
  position: absolute;
  right: 18px;
  padding: 5px 25px;
}

/****SEAT LAYOUT PAGE***/
.seat-layout-container {
  color: var(--grey);
  background-color: #fff !important;
  width: 107%;
  margin: auto;
}

.seat-cinema-screen {
  position: relative;
}

.screen-name-text {
  position: absolute;
  left: 0;
  color: var(--grey);
  font-weight: bold;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  top: -18px;
}

.category-title {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.category-title span {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  /* vertical-align: middle; */
  position: relative;
  padding: 10px 10px;
  color: var(--grey);
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize !important;
}

.category-title span:first-letter {
  text-transform: capitalize;
}

.category-title span:before,
.category-title span:after {
  content: "";
  display: block;
  width: 1000px;
  position: absolute;
  top: 50%;
  border-top: 1px solid var(--purple);
}

.category-title span:before {
  right: 100%;
}

.category-title span:after {
  left: 100%;
}

.seat-icons table {
  width: auto;
  margin: auto;
  zoom: normal;
}

.seat-layout-desc {
  display: flex;
  gap: 20px;
  justify-content: center;
  font-family: "Raleway-Medium";
}

.seat-layout-desc>div {
  display: flex;
  align-items: center;
}

.seat-layout-desc figure img {
  height: 22px;
  width: 22px;
  margin-right: 3px;
}

.timeline-border {
  border-top: 1px solid var(--grey-3);
}

.seat-order-details {
  background: var(--grey-3);
  color: var(--grey);
  font-family: "Raleway-Bold";
  width: 85%;
  margin-top: 20%;
  font-size: 18px;
  border-radius: 6px;
}

.seat-order-details .order-item {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 0;
  color: var(--grey);
}

.seat-order-details .btn-proceed {
  font-family: "Raleway-Regular";
  margin-top: 8%;
  font-size: 14px;
  width: 100%;
}

.btn-proceed:disabled:hover {
  background: linear-gradient(rgb(71, 129, 255), rgb(103, 88, 229)) !important;
  color: #ffffff !important;
}

.seat-order-details .border-tb {
  border-bottom: 1px solid var(--grey-2);
  border-top: 1px solid var(--grey-2);
}

.proceed-seat-page {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .proceed-seat-page .btn-proceed {
    width: 100%;
    margin-top: 10px;
  }

  .screen-name-text {
    font-size: 18px;
    top: 3px;
  }

  .seat-layout-desc {
    justify-content: flex-start;
  }

  .seat-order-details .order-item {
    font-size: 13px;
    line-height: 18px;
    padding: 10px;
  }

  .seat-order-details .go-back figure {
    width: 14px;
    height: -6px;
    margin-bottom: 4px !important;
  }

  .fnb-cart-total hr {
    border-top: 1px solid lightgray;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .seat-order-details .order-grid-item {
    display: grid;
    grid-template-columns: 60% 40%;
  }

  .seat-order-details .border-right {
    border-right: 1px solid var(--grey-2) !important;
  }

  .seat-order-details .cus-dropdown-1 .btn {
    font-size: 14px;
    color: var(--grey-2);
  }

  .seat-order-details .cus-dropdown-1 .dropdown-toggle::after {
    background-size: 20px;
    width: 20px;
    height: 20px;
    top: 2px;
  }
}

@media (max-width: 767px) {
  .seat-order-details {
    width: 95% !important;
    margin: auto;
    color: var(--grey-2);
  }
}

@media (min-width: 1200px) {
  .seat-order-details .border-top {
    border-top: 1px solid var(--grey-2) !important;
  }
}

/* .seat-order-details hr {
  border-top: 1px solid var(--grey-2);
  margin: 0.5rem 0 0.5rem 0;
} */

/*****OFFERS PAGE*****/
.offer-page {
  color: var(--grey);
}

.offer-name-1 {
  font-size: 14px;
}

.offer-inner-accordion .card-body {
  padding: 0px !important;
}

.offer-inner-accordion span {
  font-size: 16px;
}

.offer-inner-accordion .card-header:before {
  width: 50%;
  left: 0px;
}

.offer-inner-accordion .card-header:after {
  left: 51%;
  right: auto;
}

.offer-page .form-error-text {
  font-size: 12px;
  padding: 0;
}

.offer-page .custom-accordion-2 .card-body {
  padding: 0 0 0 30px;
}

.offer-page .custom-accordion-2 .card {
  background: white !important;
  border: 1px solid white !important;
}

.offer-page .custom-accordion-2 .card-header {
  background: white !important;
  border: 1px solid white !important;
}

.custom-accordion-2 span {
  background: white !important;
}

.offer-page .btn-offer-selected {
  background: white !important;
  font-size: 14px;
  border: 1px solid #fff !important;
  color: rgba(32, 178, 32, 0.648) !important;
  font-weight: bold;
}

.btn-offer-1,
.btn-apply-1,
.btn-proceed-1,
.btn-apply,
.btn-offer-2,
.btn-proceed {
  font-size: 13px;
  min-width: 120px;
}

.btn-offer-2 {
  padding: 05px !important;
}

.btn-proceed.inactive-btn {
  background: var(--grey-2) !important;
  cursor: none;
  color: #ffffff !important;
}

.btn-proceed.inactive-btn:hover {
  background: var(--grey-2) !important;
  cursor: not-allowed !important;
  color: #ffffff !important;
  font-weight: 100 !important;
  border-color: var(--grey-2) !important;
}

.offer-grid-1 {
  display: grid;
  grid-template-columns: 64% 25%;
  align-items: center;
}

.offer-card-number {
  display: grid;
  /* grid-template-columns: 25% 25% 22%; */
  grid-template-columns: 23% 15% 20% 15% !important;
  grid-column-gap: 10px;
  /* grid-row-gap: 10px; */
  /* align-items: center; */
}

.card-number-cross {
  text-align: center;
  font-weight: bold;
  margin-top: 10px !important;
}

.sm-text {
  font-size: 13px;
}

.offer-page .icon-cross {
  background: transparent;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.offer-page .icon-cross svg path {
  fill: var(--grey);
}

.offer-details {
  font-size: 14px;
  line-height: 14px;
}

@media (max-width: 767px) {
  .offer-page .icon-cross svg {
    margin-top: 5px !important;
  }

  .offer-card-number {
    display: grid;
    grid-template-columns: 30% 24% 20% 15% !important;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    /* grid-row-gap: 10px; */
  }

  .flex-5 {
    display: flex;
    justify-content: space-between;
    margin-top: 05px;
  }

  .btn-offer-2,
  .btn-apply {
    margin-top: 05px;
  }

  .offer-page .custom-accordion-2 .card-body {
    padding: 0 0 0 20px;
  }
}

.offer-page .form-control {
  background-color: transparent;
  border: 1px solid var(--grey-2);
}

.icon-applied svg {
  width: 20px;
  height: 35px;
}

.icon-applied svg path {
  fill: var(--purple);
}

.flex-6 {
  display: flex;
  align-items: center;
  gap: 0px;
}

.offer-name-heading {
  font-size: 15px;
  color: var(--grey-2);
  display: inline-block;
  border-bottom: 1px solid var(--grey-2);
  font-weight: bold;
}

.tnc-data {
  font-size: 12px;
  margin: 0;
  padding: 10px 9px;
  list-style-type: none;
  line-height: 15px;
}

.offer-card-section .flex-3 {
  display: flex;
}

@media (min-width: 1200px) {
  .offer-grid-1 {
    display: grid;
    grid-template-columns: 30% 25%;
    align-items: center;
  }

  .offer-card-section {
    display: flex;
    align-items: start;
    gap: 10px;
  }

  .offer-card-section .flex-1,
  .offer-card-section .flex-3 {
    display: flex;
    gap: 05px;
  }

  .flex-4 {
    display: grid;
    grid-template-columns: 40% 50%;
  }

  .flex-5 {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .privacy-policy-page,
  .profile-page,
  .faq-page,
  .tnc-page,
  .location-page,
  .promotion-page,
  .about-us-page,
  .feedback-page {
    min-height: 50vh !important;
  }
}

@media (min-width: 768px) {
  .offer-page .form-grid-1 {
    display: grid;
    grid-template-columns: 35% 20% 10%;
    gap: 15px;
  }
}

.privacy-policy-page,
.profile-page,
.faq-page,
.tnc-page,
.location-page,
.promotion-page,
.about-us-page,
.feedback-page {
  color: var(--grey) !important;
  min-height: 70vh !important;
}

/****CINEMA LOCATION****/
.cinema-loc-card {
  background: var(--grey-3);
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.cinema-loc-card .btn-direction {
  padding: 5px 25px;
  font-size: 14px;
}

.selected-cinema-card {
  background: linear-gradient(rgb(71, 129, 255), rgb(103, 88, 229)) !important;
  color: #fff;
}

.selected-cinema-card .btn-direction {
  background: #fff !important;
  color: var(--purple) !important;
}

.promotion-card {
  background: var(--grey-3);
  padding: 20px;
  margin-bottom: 20px;
}

.promotion-card .btn-details {
  padding: 5px 25px;
  font-size: 14px;
  margin-top: 40px;
}

.promotion-content {
  font-size: 14px;
}

/***FNB PAGE***/
.fnb-page-content {
  font-family: "Raleway-Regular";
}

.fnb-li-items {
  padding: 0;
  margin: 0;
  display: flex;
  list-style-type: none;
  gap: 25px;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 16px;
}

.fnb-li-items li {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  color: var(--grey-2);
}

.fnb-li-items .active,
.fnb-li-items li:hover {
  color: var(--purple);
  font-family: "Raleway-SemiBold";
  transform: scale(1.1);
  transition: 0.1s;
}

.fnb-card-item {
  /* background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)); */
  background: #fff;
  border-radius: 08px;
  border: 1px solid var(--purple);
  /* color: #EBEBEB; */
  color: #8d8d8d;
  /* display: flex;
  flex-direction: column; */
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 6px 2px;
  cursor: pointer;
}

.fnb-card-item img {
  height: 185px;
  border-radius: 5px !important;
}

.fnb-card-item .fnb-name {
  height: 45px;
  -webkit-line-clamp: 1;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  /* text-overflow: ellipsis;
  white-space: nowrap; */
}

.fnb-card-item .fnb-price {
  font-family: "Raleway-Bold";
}

.fnb-order-details {
  background: var(--grey-3);
  color: var(--grey);
  font-family: "Raleway-Bold";
  margin-top: 20%;
  font-size: 12px;
  border-radius: 10px;
  position: sticky;
  top: 22%;
}

.fnb-order-details .cart-grid-item {
  display: grid;
  grid-template-columns: 60% 40%;
  font-family: "Raleway-Bold";
  font-size: 12px;
}

.mobile-fnb-grid {
  display: flex;
  /* grid-template-columns: repeat(10, auto); */
  overflow: auto;
  background: var(--grey-3);
  grid-gap: 10px;
  gap: 10px;
  overflow: auto;
}

.mobile-fnb-grid .mobile-fnb-cart {
  background: linear-gradient(rgb(71, 129, 255), rgb(103, 88, 229)) !important;
  border-radius: 05px;
  position: relative;
  height: 60px;
  width: 100%;
  flex-basis: 0 0 24%;
}

.mobile-fnb-total {
  background: white !important;
  height: 60px;
  /* width: 60px; */
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.fs-18 {
  font-size: 18px;
}

.mobile-fnb-cart .fnb-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.mobile-fnb-cart .fnb-img img {
  width: 40px;
  height: 40px;
}

.mobile-fnb-cart .fnb-total-item {
  background: white;
  position: absolute;
  width: 15px;
  font-size: 12px;
  text-align: center;
}

.mobile-fnb-cart .fnb-delete {
  background: white;
  position: absolute;
  width: 08px;
  height: 08px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  top: 0;
  right: 0;
}

/***FAQ PAGE****/
@media (min-width: 1400px) {
  .faq-nav-tabs {
    width: 80%;
    margin: 0 auto;
  }
}

.border-bottom-faq {
  border-bottom: 1px solid var(--grey-2);
}

.faq-tab {
  background: #cccccc;
  color: var(--grey);
  padding: 10px;
  border-radius: 05px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-mobile-grid {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  gap: 10px;
}

.faq-nav-tabs .spinner-border {
  margin: 30px auto;
  display: block !important;
}

/***PROFILE PAGE***/
.profile-page {
  position: relative;
}

.profile-nav-tabs {
  border-bottom: 1px solid var(--grey-2) !important;
}

.profile-page .booking-history-scroll {
  height: 70vh;
  overflow: auto;
}

.profile-page .btn-logout {
  font-size: 14px;
  position: absolute;
  top: 35px;
  right: 100px;
  padding: 5px 3rem;
}

.user-booking-history {
  display: grid;
  grid-template-columns: 08% 26% 14% 22% 20%;
  font-size: 14px;
  font-family: "Raleway-Bold";
  margin-bottom: 20px;
  grid-gap: 20px;
  background: var(--grey-3);
  border-radius: 08px;
  padding: 15px 10px 15px 40px;
  /* align-items: center; */
}

.user-booking-history>div {
  display: flex;
  flex-direction: column;
  gap: 05px;
  position: relative;
  justify-content: center;
}

.user-booking-history .qr-code-img {
  width: 65%;
}

.user-booking-history .total-amt {
  font-size: 25px;
}

@media (min-width: 1400px) {
  .profile-edit-section {
    width: 85%;
    margin-top: 50px;
  }

  .user-booking-history>div:not(:first-child):not(:last-child):before {
    display: block;
    content: "";
    border-right: 1px solid var(--grey-2);
    height: 90px;
    position: absolute;
    top: 25px;
    right: 10px;
  }
}

@media (max-width: 999px) {
  .profile-page .btn-logout {
    font-size: 12px;
    position: absolute;
    top: 0px;
    right: 20px;
    padding: 4px 1rem;
  }
}

@media (max-width: 768px) {
  .section-min-height {
    min-height: 55vh;
  }

  .profile-edit-section {
    margin-top: 10px;
  }

  .user-booking-history {
    grid-template-columns: 100%;
    padding: 08px;
    gap: 2px;
    font-size: 13px;
    line-height: 18px;
  }

  .user-booking-history .movie-img {
    text-align: center;
  }

  /* .user-booking-history .movie-img img {
    width: 60%;   
  } */
  .user-booking-history .qr-code-img {
    width: 90%;
  }

  .user-booking-history .mobile-grid-1 {
    display: grid;
    grid-template-columns: 30% 66%;
    gap: 10px;
  }

  .user-booking-history .mobile-grid-2 {
    display: grid;
    grid-template-columns: 100%;
  }

  .user-booking-history .total-amt {
    font-size: 18px;
  }
}

.profile-details-section {
  background: #cccccc;
  padding: 10px;
  position: relative;
}

.profile-edit-icon {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
}

/****SEARCH PAGE****/
.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
}

.a-link,
.a-link:hover {
  color: white;
  text-decoration: none;
}

.fnb-mobile-modal-details {
  font-size: 15px;
  line-height: 16px;
}

.exp-flex {
  display: flex;
  overflow: auto;
}

.exp-card {
  position: relative;
  margin-bottom: 8px;
}

.exp-card img {
  height: 265px !important;
  object-fit: cover;
}

.exp-flex>div {
  flex: 0 0 200px;
  margin-right: 10px;
}

.exp-flex .exp-overlay {
  position: absolute;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(25%, rgba(0, 0, 0, 0)),
      color-stop(99.5%, #000000));
  bottom: -3px;
  width: 100%;
  top: 0;
  border-radius: 15px;
}

.exp-card.active .exp-overlay {
  position: absolute;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(25%, rgba(0, 0, 0, 0)),
      color-stop(99.5%, rgb(71, 129, 255)));
  /* linear-gradient(rgb(71, 129, 255), rgb(103, 88, 229)) */
  bottom: -3px;
  width: 100%;
  top: 0;
  border-radius: 15px;
}

.exp-flex .exp-text {
  display: flex;
  /* justify-content: center; */
  align-items: end;
  height: 100%;
  color: white;
  padding: 20px;
}

@media only screen and (max-width: 450px) {
  .seat-layout-container {
    width: 100% !important;
  }
}

.spacing-1 {
  padding-top: 6px !important;
}

.spacing-2 {
  padding: 9px 0 9px 0 !important;
}

.btn-offer-2 {
  margin-top: 9px !important;
}

[dir="rtl"] .custom-dropdown-2 .dropdown-toggle:after {
  margin-right: 3px !important;
}

[dir="rtl"] .custom-dropdown-1 .dropdown-toggle:after {
  margin-right: 3px !important;
}

[dir="rtl"] .advance-lg::before {
  content: "حجز مسبق";
}

[dir="rtl"] .banner-hero-content .go-back {
  right: 95px;
}

[dir="rtl"] .movie-details-container {
  text-align: right;
}

[dir="rtl"] .flow-timeline:not(:last-child):after {
  left: -68px;
}

@media (max-width: 767px) {
  [dir="rtl"] .flow-timeline:not(:last-child):before {
    left: 45px;
    width: 140px;
    left: 60px;
  }

  [dir="rtl"] .flow-timeline:not(:last-child):after {
    width: 100px;
    left: -50px;
  }
}

[dir="rtl"] .custom-accordion-2 .card-header:after {
  left: 0;
  right: auto;
}

[dir="rtl"] .custom-accordion-2 .card-header {
  text-align: right;
}

[dir="rtl"] .custom-accordion-2 .card-header::before {
  width: 92%;
  left: 30px !important;
  right: 0px !important;
}

[dir="rtl"] .offer-details {
  text-align: right;
}

[dir="rtl"] .btn-offer-2 {
  margin-left: auto;
  display: block;
}

[dir="rtl"] .text-extrabold {
  text-align: right !important;
}

[dir="rtl"] .profile-page .btn-logout {
  left: 100px;
  right: auto;
}

[dir="rtl"] .user-booking-history>div:not(:first-child):not(:last-child):before {
  left: 0px;
  right: auto;
}

[dir="rtl"] .user-booking-history {
  text-align: right;
}

[dir="rtl"] .cart-grid-item {
  text-align: right;
}

[dir="rtl"] .coloumn {
  text-align: right;
}

[dir="rtl"] .fnb-order-details .btn-proceed {
  text-align: right;
  display: block;
  margin-left: auto;
}

[dir="rtl"] .btn-direction {
  display: block;
  margin-left: auto;
}

[dir="rtl"] .custom-accordion-3 .card-header:after {
  left: 7px;
  right: auto;
}

[dir="rtl"] .custom-accordion-3 .card-header.hidden:before {
  left: 5px;
  right: auto;
}

[dir="rtl"] .custom-accordion-3 .card-header {
  text-align: right;
}

[dir="rtl"] .custom-accordion-3 .card-body {
  text-align: right;
}

[dir="rtl"] .about-us-page p {
  text-align: right !important;
}