:root {
  --club-1: #cccccc;
  --club-2: #e2b950;
  --club-3: rgb(98, 54, 255);
  --dark-1: #151515;
  --dark-2: #0f0f0f;
  --dark-3: #222222;
  --light-1: #cccccc;
}

/* Club Cinepolis Fonts */
@font-face {
  font-family: "clubBold";
  src: url("../fonts/TYPETYPE-TT NORMS MEDIUM.OTF");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "clubExtraBold";
  src: url("../fonts/TYPETYPE-TT NORMS EXTRABOLD.OTF");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "clubMedium";
  src: url("../fonts/TYPETYPE-TT NORMS MEDIUM.OTF");
  font-style: normal;
  font-display: swap;
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Club Cinepolis Register*/
.club-cinepolis-register {
  /* background-image: url("../pictures/club-cinepolis/background.png"); */
  background-position: center;
  background-size: 100%;
  font-family: "clubMedium";
}

.club-cinepolis-register > .container {
  background-color: black;
}

.club-cinepolis-register__join__banner > img {
  width: 100%;
}

.club-cinepolis-register__headline {
  font-size: clamp(20px, 3vw, 36px);
  text-align: center;
  color: var(--club-1);
  margin: clamp(16px, 2.5vw, 24px) 0;
  /* font-weight: bold; */
  font-family: 'Raleway', sans-serif;
}

/* Club Cinepolis Input */

.club-cinepolis__input {
  margin-bottom: 16px;
  /* width: 100%; */
}

.club-cinepolis__input label {
  width: 100%;
  position: relative;
  margin-bottom: 0px;
}

.club-cinepolis__input label > img {
  position: absolute;
  bottom: 14px;
  right: 8px;
  width: 30px;
}

.club-cinepolis__input input {
  outline: none;
  border: none;
  height: 45px;
  background-color: transparent;
  color: #7d7d7d;
  width: 100%;
  border-bottom: 1px solid #7d7d7d;
  display: flex;
  align-items: center;
  /* padding: 0 48px 0 16px; */
  font-size: 16px;
  /* font-weight: normal; */
}

.club-cinepolis__input input:disabled {
  background-color: #e7e7e7 !important;
}

.club-cinepolis__input input[type="date"] {
  padding-right: 16px;
}

.club-cinepolis__input input[type="checkbox"],
.club-cinepolis__input input[type="radio"] {
  height: 0;
  width: 0;
}

.club-cinepolis__input__label {
  font-size: 16px;
}

.club-cinepolis__input.checkmark-span .club-cinepolis__input__label {
  margin-left: 32px !important;
}

.club-cinepolis__input.checkmark-span label > input ~ span {
  position: absolute;
  top: 4px;
  cursor: pointer;
  border: 2px solid var(--club-1);
  border-radius: 2px;
  width: 16px;
  height: 16px;
  color: var(--club-1);
}

.club-cinepolis__input.checkmark-span label > input ~ span::after {
  content: url(../pictures/club-cinepolis-new/check-white.svg);
  position: absolute;
  top: -6px;
  transform: scale(0);
  transition: transform 0.25s;
  filter: invert(0.5);
}

.club-cinepolis__input.checkmark-span
  label
  > input[type="radio"]
  ~ span::after {
  content: "";
  width: 70%;
  height: 70%;
  background: var(--club-2);
  top: 2px;
  left: 2px;
  border-radius: 50%;
}

.club-cinepolis__input.checkmark-span label > input:checked ~ span::after {
  transform: scale(1);
}

.club-cinepolis__input .club-cinepolis__input__error {
  color: var(--danger);
  font-size: 12px;
  text-align: left;
  margin-left: 8px !important;
}

/* For Customizing the date type input */
/* .club-cinepolis__input input::-webkit-datetime-edit {
  padding: 1em;
}
.club-cinepolis__input input::-webkit-datetime-edit-fields-wrapper {
  background: silver;
}
.club-cinepolis__input input::-webkit-datetime-edit-text {
  color: red;
  padding: 0 0.3em;
}
.club-cinepolis__input input::-webkit-datetime-edit-month-field {
  color: blue;
}
.club-cinepolis__input input::-webkit-datetime-edit-day-field {
  color: green;
}
.club-cinepolis__input input::-webkit-datetime-edit-year-field {
  color: purple;
}
.club-cinepolis__input input::-webkit-inner-spin-button {
  display: none;
} */
.club-cinepolis__input input::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}

.club-cinepolis__input input[type=date]:invalid::-webkit-datetime-edit {
  color: #999;
}

.club-cinepolis__input a {
  color: #7d7d7d !important;
}

.club-cinepolis__input a:hover {
  color: #ffffff !important;
}


.club-cinepolis__input a:active,
.club-cinepolis__input a:visited,
.club-cinepolis__input a:focus {
  color: var(--club-2);
}

/* Club Cinepolis Dropdown */

.club-cinepolis__dropdown {
  margin-bottom: 16px;
  font-family: "clubMedium";
}

.club-cinepolis__dropdown.country__code {
  padding-right: 8px;
  margin-right: 8px;
  /* border-right: 1px solid white; */
}

.club-cinepolis__dropdown .btn {
  border: none !important;
  background-color: transparent !important;
  border-bottom: 1px solid grey !important;
  border-radius: 0px;
  padding-right: 10px;
  width: 100% !important;
  height: 45px !important;
  font-family: "clubMedium" !important;
  color: #adadad;
  display: flex;
  align-items: center;
}

.club-cinepolis__dropdown .dropdown-menu {
  background-color: var(--dark-1);
  width: 100% !important;
}

.club-cinepolis__dropdown .dropdown-item {
  color: white;
  background-color: transparent;
  font-family: "clubMedium" !important;
}

.club-cinepolis__dropdown .dropdown-item:hover,
.club-cinepolis__dropdown .dropdown-item:active,
.club-cinepolis__dropdown .dropdown-item:focus {
  background-color: white;
  color: var(--dark-1);
}

.club-cinepolis__dropdown__item {
  display: flex;
  align-items: center;
}

.club-cinepolis__dropdown__item > img {
  width: 32px;
  margin-right: 8px;
}

/* Club Cinepolis Button */
.club-cinepolis__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-weight: bold; */
  font-family: "clubBold";
  font-size: 16px;
  height: 45px;
  padding: 3px 16px 0;
  border-radius: 8px;
  background-color: var(--club-1);
  color: black;
  outline: none;
  border: 1px solid transparent;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s,
    transform 0.5s;
}

.club-cinepolis__btn:hover,
.club-cinepolis__btn:active {
  background-color: black;
  color: var(--club-1);
  border-color: var(--club-1);
}

.club-cinepolis__btn:focus {
  outline: none;
}

.club-cinepolis__btn:active {
  transform: scale(0.9);
}

.club-cinepolis__btn.disabled {
  cursor: default;
  pointer-events: none;
  color: grey;
  background-color: var(--dark-1);
  border-color: grey;
}

.club-cinepolis__btn.loading {
  cursor: default;
  pointer-events: none;
}

.club-cinepolis__btn.loading::after {
  content: "";
  width: 24px;
  height: 24px;
  margin-left: 16px;
  border: 2px solid black;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear;
}

/* Club Cinepolis Profile */

.club-cinepolis-profile {
  font-family: "clubMedium";
}

.club-cinepolis-profile .club-cinepolis-profile__headline {
  color: var(--club-1);
  font-size: clamp(24px, 3vw, 36px);
  text-align: center;
  /* font-weight: bold; */
  font-family: 'Raleway', sans-serif;
  margin-bottom: 24px;
}

.club-cinepolis-profile .club-cinepolis-profile__synopsis {
  font-size: clamp(16px, 2vw, 20px);
}

.club-cinepolis-profile .club-cinepolis-profile__sub-headline {
  font-size: clamp(18px, 2.5vw, 24px);
  /* font-weight: bold; */
  font-family: "clubBold";
}

/* Club Cinepolis Card */
.club-cinepolis-profile .club-cinepolis-card {
  position: relative;
  font-size: clamp(12px, 1.5vw, 16px);
}

.club-cinepolis-profile .club-cinepolis-card__img {
  position: relative;
  width: 100%;
}

.club-cinepolis-profile .club-cinepolis-card__content {
  border: 1px solid var(--club-1);
  border-radius: 35px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: clamp(20px, 3vw, 32px);
  display: flex;
  align-items: center;
}

.club-cinepolis-card.tier__one {
  color: white;
}

.club-cinepolis-card.tier__two {
  color: black;
  /* font-weight: bold; */
  font-family: "clubBold";
}

.club-cinepolis-card .club-cinepolis-card__number,
.club-cinepolis-card .club-cinepolis-card__name,
.club-cinepolis-card .club-cinepolis-card__tier {
  /* font-weight: bold; */
  font-family: "clubBold";
  /* font-size: 18px; */
}

.club-cinepolis-card .club-cinepolis-card__content__title {
  font-family: 'Raleway', sans-serif;
}

/* Club Checkout */
.club-checkout {
  font-size: 16px;
  font-family: "clubMedium";
  margin-bottom: clamp(150px, 20vw, 200px);
}

.club-checkout .club-checkout__user__details > div > h2 {
  font-size: clamp(18px, 2vw, 24px);
  font-family: "clubBold";
}

.club-checkout .club-cinepolis__input input {
  background-color: white;
  color: black;
  border: none;
  border-radius: 8px;
}
.club-cinepolis__input input::placeholder {
  color: #adadad;
}

.club-checkout .club-checkout__points__redemption > div > h1 {
  font-size: clamp(28px, 3vw, 36px);
  font-family: 'Raleway', sans-serif;
  color: var(--club-1);
  text-align: center;
}

.club-checkout .club-checkout__points__redemption__tier {
  border: 2px solid var(--club-1);
  border-radius: 8px;
  padding: 8px 16px;
  margin-right: 16px;
  text-align: center;
}

.club-checkout .club-checkout__points__redemption__tier,
.club-checkout .club-checkout__points__redemption__points {
  font-size: clamp(16px, 1.5vw, 18px);
}

.club-checkout .club-checkout__points__redemption__tier > h3 {
  font-size: clamp(20px, 2.25vw, 28px);
  margin-bottom: 0;
}

.club-checkout .club-checkout__points__redemption__points {
  text-align: center;
}

.club-checkout .club-checkout__points__redemption__points > h3 {
  font-size: clamp(24px, 2.5vw, 32px);
  font-family: "clubBold";
  margin-bottom: 0;
}

.club-checkout .club-checkout__accordion .card {
  background: transparent !important;
  color: white !important;
  margin-bottom: 16px;
}

.club-checkout .club-checkout__accordion .card-header {
  /* background: linear-gradient(var(--club-1), var(--club-2)) !important ; */
  background: var(--club-3) !important ;
  color: #fff !important;
  cursor: pointer;
  position: relative;
  border-radius: 8px 8px 0 0;
  font-family: "clubBold";
  font-size: clamp(18px, 2vw, 20px);
}

.club-checkout .club-checkout__accordion .card-header::after {
  content: url("../pictures/club-cinepolis-new/arrow-down-black.svg");
  position: absolute;
  right: 16px;
  transition: transform 0.25s;
}

.club-checkout .club-checkout__accordion .card-header.active::after {
  transform: rotate(180deg);
}

.club-checkout .club-checkout__movie_details {
  display: flex;
  align-items: flex-end;
}

.club-checkout .club-checkout__movie_details figure {
  width: 100px;
  margin-bottom: 0;
  margin-right: 16px;
}

.club-checkout .club-checkout__movie_details figure > img {
  width: 100%;
}

.club-checkout .club-checkout__movie_details h3 {
  font-size: clamp(18px, 2vw, 24px);
  font-family: "clubBold";
  margin-bottom: 8px;
}

.club-checkout .club-checkout__movie_details p:not(:last-child) {
  margin-bottom: 4px;
}

.club-checkout .club-checkout__order_details .ordered__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.club-checkout .club-checkout__order_details .ordered__item.__modifier {
  display: none;
  padding-left: 16px;
}

.club-checkout
  .club-checkout__order_details
  .__modifier_group.show
  .__modifier {
  display: flex;
}

.club-checkout .club-checkout__order_details > .ordered__item {
  border-bottom: 1px solid white;
  padding-bottom: 8px;
}

.club-checkout .club-checkout__order_details > .ordered__item:not(:last-child) {
  margin-bottom: 8px;
}

.club-checkout .club-checkout__order_details .ordered__item > span {
  font-size: clamp(14px, 1.5vw, 16px);
}

.club-checkout
  .club-checkout__order_details
  > .ordered__item:last-child
  > span {
  font-size: clamp(16px, 1.5vw, 18px);
}

.club-checkout
  .club-checkout__order_details
  .ordered__item
  > span:nth-child(2) {
  font-family: "clubBold";
  margin-left: 16px;
}

.club-checkout .club-checkout__order_details .ordered__item.__modifier > span {
  font-size: clamp(12px, 1.2vw, 14px);
}

.club-checkout .club-checkout__order_details .ordered__item > span > span {
  font-size: clamp(10px, 1vw, 12px);
}

.club-checkout
  .club-checkout__order_details
  .ordered__item
  img.modifier__toggle {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.25s;
}

.club-checkout
  .club-checkout__order_details
  .__modifier_group.show
  .modifier__toggle {
  transform: rotate(180deg) translateX(-16px);
}

.club-checkout .club-checkout__tnc {
  position: fixed;
  bottom: 0;
  z-index: 100;
  left: 0;
  width: 100%;
  padding: 16px;
  background-color: var(--dark-1);
}

.club-checkout .club-checkout__tnc > article {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.club-checkout .club_checkout_payment_method h6 {
  font-size: clamp(18px, 2vw, 24px);
  font-weight: bold;
}

.club-checkout .club_checkout_payment_method .club-cinepolis__input__label {
  font-size: 18px;
}

.club-checkout .club_checkout_payment_method .club-cinepolis__input {
  width: auto;
}

/* Loyalty Otp Popup */
.loyalty-otp-popup .modal-dialog {
  max-width: 600px;
}

.loyalty-otp-popup .container-fluid.wrapper {
  background-color: #eaeaea;
  border-radius: 12px;
  padding: clamp(16px, 4vw, 48px);
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.loyalty-otp-popup .container-fluid.wrapper h1 {
  font-family: 'Raleway', sans-serif;
  font-size: clamp(18px, 2.5vw, 26px);
  font-weight: bold;
  color: var(--grey);
}

.loyalty-otp-popup .container-fluid.wrapper h2 {
  font-size: clamp(14px, 1.5vw, 16px);
  font-weight: 100 !important;
}

.loyalty-otp-popup .container-fluid.wrapper .club-cinepolis__input {
  width: min(100%, 400px);
  margin: 20px auto 16px;
}

.loyalty-otp-popup .container-fluid.wrapper .club-cinepolis__input input {
  border: none;
  background-color: white;
  color: var(--dark-2);
  border-radius: 8px;
  text-align: center;
}

.club-register-btn {
  width: 30%;
}

.club-placeholder {
  color: #afafaf;
  margin-top: 7px !important;
}

/* Media Queries */
@media (max-width: 767px) {
  .club-cinepolis-profile {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 5000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 0 15px;
    overflow-y: auto;
  }

  .club-cinepolis-register {
    /* background-image: url("../pictures/club-cinepolis/background-mobile.png"); */
    padding: 0 30px;
  }
  .club-register-btn {
    width: 100%;
    font-size: 14px !important;
  }
}

@media (min-width: 767px) {
  .club-checkout .club-checkout__points__redemption {
    border-left: 2px solid var(--club-1);
  }
}

@media (min-width: 1200px) {
  .club-checkout .club-checkout__points__redemption {
    border-right: 2px solid var(--club-1);
  }
}

.club-checkout__tnc a {
  color: var(--club-3) !important;
}
.club-checkout__tnc .club-cinepolis__input.checkmark-span label > input ~ span {
  border: 2px solid var(--club-3);
  color: var(--club-3);
}
.club-input__btn-2 {
  background: var(--club-3);
  color: #fff;
}
.club-checkout__tnc
  .club-cinepolis__input.checkmark-span
  label
  > input
  ~ span::after {
  content: url(../pictures/club-cinepolis-new/check.svg);
}

.club-cinepolis__table th,
.club-cinepolis__table td {
  color: white !important;
  color: white !important;
  padding: 08px 5px !important;
}

.club-cinepolis__table th {
  color: var(--club-2) !important;
  text-transform: uppercase;
}
/***Club Cinepolis Profile***/








.tier-percentage {
  font-size: clamp(70px, 2vw, 22px);
  text-align: left;
  margin-bottom: 0px;
  font-weight: bold;
}
.tier-sm-desc {
  color: var(--club-1);
  text-align: left;
}
.hcw__section__three .btn-white {
  padding: 5px 50px;
  font-size: 24px;
  font-weight: bold;
  background: #fff;
  border-radius: 08px;
}
.hcw__process {
  border: 1px solid white;
  border-radius: 12px;
  padding: 20px;
  min-height: 200px;
}



/* Cinepolis Club New */

.club-cinepolis-about-main {
  padding: 25px 55px 25px 55px;
  color: #000;
  max-width: 1320px;
  margin: auto;
}

.club-cinepolis-about-main-profile {
  padding: 25px 25px 25px 25px;
  background-color: #eaeaea;
  color: #000;
}

.club-card-image {
  position: relative;
}

.club-card-image img {
  width: 290px;
}

.club-card-silver {
  position: absolute;
}

.club-card-silver{
  left: 90px;
  top: -25px;
}

.black-tier {
  display: flex;
  align-items: center;
  border-radius: 22px;
  padding: 0px 20px;
  /* border: 3px solid #000000; */
  background-image: url("../pictures/club-cinepolis-new/black-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  font-weight: bold;
  height: 75px;
}

.black-tier svg {
  fill: #ffffff;
}

.black-tier-percent {
  font-size: 35px;
  font-weight: 800;
}

.silver-tier {
  display: flex;
  align-items: center;
  /* border: 3px solid black; */
  border-radius: 22px;
  padding: 0px 20px;
  background-image: url("../pictures/club-cinepolis-new/silver-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
  font-weight: bold;
  height: 75px;
}

.silver-tier svg {
  fill: #000000;
}

.club-btn-purple {
  background-color: #7563b6;
  color: #ffffff;
  padding: 8px 30px 8px;
}

.club-bg-silver {
  background-image: url("../pictures/club-cinepolis-new/silver-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 70px;
}

.club-bg-black {
  background-image: url("../pictures/club-cinepolis-new/black-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 70px;
}

.club-bg-black-detail {
  background-image: url("../pictures/club-cinepolis-new/black-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 5px 30px 15px 30px;
  margin: 25px 0px 10px 0px;
  border-radius: 10px;
  color: #ffffff;
}

.club-bg-silver-detail {
  background-image: url("../pictures/club-cinepolis-new/silver-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 5px 30px 15px 30px;
  margin: 25px 0px 10px 0px;
  border-radius: 10px;
  color: #000000;
}

.btn-black {
  background: #000000 !important;
  color: var(--white) !important;
  transition: background-color 1000s;
}

.club-info-background-img {
  position: absolute;
}

.club-info-main-txt {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
}

.club-info-main-txt-s {
  font-size: 35px;
  font-weight: bold;
  color: #000000;
}

.club-info-profile-card {
  color: #ffffff;
}

.club-info-card-details {
  font-weight: bold;
  line-height: 1.8;
}

.club-info-tier-details {
  font-weight: bold;
}

.club-info-tier-details .tier {
  font-size: 20px;
}

.club-info-tier-details .points {
  color: #000000;
  background-color: #ffffff;
  width: max-content;
  font-size: 16px;
  font-weight: 200;
}

.club-info-tier-details .points-s {
  color: #ffffff;
  background-color: #000000;
  width: max-content;
  font-size: 25px;
  font-weight: 200;
}

.club-info-image {
  width: 50px;
  filter: invert(1);
}

.club-member-label {
  background-color: #c2c2c2;
  padding: 6px 0px 6px;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
}

.club-member-text {
  padding: 15px 20px 15px;
  text-align: center;
  font-size: 14px;
}

.info-main-transaction {
  margin-left: -5px !important;
  margin-right: -5px !important; 
}

.info-main-transaction .col{
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.redeem-details-main {
  color:#7d7d7d;
  line-height: 1.9;
}
/* .redeem-details-main h4 b{
  color: #000000;
} */
.text-grey-club {
  color: #7d7d7d;
  margin-top: auto;
  line-height: 2.5;
  font-weight: 600;
}

.text-grey-club img {
  width: 30px;
}

.movie-redem-img {
  width: 25%;
}

.movie-redem-img img{
  border-radius: 10px;
}

.btn-purple-club {
  background-color: #7563b6;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #7563b6;
  padding: 6px 35px 4px;
}

.redeem-order-details {
  background-color: #E8E8E8 ;
  border-radius: 10px;
  padding: 15px;
  color:#7d7d7d;
  /* height: 100px; */
  /* width: 80%; */
}
.redeem-order-details1 {
  background-color: #E8E8E8 ;
  border-radius: 10px;
  padding: 15px;
  color:#7d7d7d;
  /* height: 100px; */
  /* width: 80%; */
}

.fnb-redeem-detail {
  overflow-y: scroll;
}

.border-none {
  border: none;
}

.points-to-redeem .col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.redeem-white-bg {
  background-color: #E8E8E8;
  border-radius: 10px;
  padding: 4px 10px 4px;
  width: 60%;
}

.redeem-white-bg input{
  background-color: #E8E8E8;
}

.redeem-white-bg input::placeholder{
  color: #a7a7a7;
}

.redeem-main-div {
  color:#7d7d7d !important;
  max-width: 1380px;
}

.redeem-col1 {
  padding-left: 0px !important;
}

.redeem-col2 {
  padding-right: 0px !important;
}
.redeem-col2s {
  padding-left: 0px !important;
}

.club-cinepolis-signup-main {
  padding: 30px 50px 50px 50px;
  /* background-color: #eaeaea; */
  color: #5c5c5c;
}

.fw-reg-club-new {
  font-weight: normal;
  border-bottom: 1px solid #7d7d7d;
  height: 45px;
}

.fw-reg-club-new button{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  background-color: #ffffff !important;
  border: 0px !important;
  color: #7d7d7d !important;
  font-size: 16px;
  padding-left: 2px;
} 

.fw-reg-club-new .show > .btn-primary.dropdown-toggle,.fw-reg-club-new .btn-primary:focus {
  border: none !important;
  color: #7d7d7d !important;
}

.checkout-bg-card-black {
  background-image: url("../pictures/club-cinepolis-new/black-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 15px;
}

.tnc-text-white {
  color: #ffffff;
}
.tnc-text-white:hover {
  color: #ffffff;
}

.club-cinepolis__input input {
  outline: none;
  border: none;
  height: 45px;
  background-color: transparent;
  color: #7d7d7d;
  width: 100%;
  border-bottom: 1px solid #7d7d7d;
  display: flex;
  align-items: center;
  /* padding: 0 48px 0 16px; */
  font-size: 16px;
  /* font-weight: normal; */
}

.club-cinepolis__input input::placeholder {
  color: #afafaf;
}

.bg-section-order-club {
  height: auto !important;
  overflow-y: auto;
}

.club-cinepolis-mobile {
  height: 30px !important;
  padding-bottom: 15px !important;
}

.club-cinepolis-pass:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px #ffffff inset;
}

/* input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #eaeaea inset;
}  */

.row-margin-minus {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.club-signup-head-text h4{
  line-height: 1.4;
}

.loyalty-close-btn .close{
  right: 20px;
  top: 12px;
  position: absolute;
  font-size: 1.8rem;
}

.loyalty-alert-class .alert{
  position: relative;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 0px;
}

.loyalty-close-btn-signup .close{
  right: -12px;
  top: 6px;
  position: absolute;
  font-size: 1.8rem;
}

.float-btn-club{
	position:fixed;
	width:60px;
	height:60px;
	bottom:75px;
	right:25px;
	background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229));
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.float-btn-club .my-float{
	margin-top:22px;
}

.float-btn-club img {
  width: 40px;
}

.loy-tier-img {
  gap: 16px ;
}

.fs-16 {
  font-size: 16px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.loyalty-register-static .register-now {
  font-size: 16px !important;
  width: 210px;
}

.loyalty-register-static .club-card-black {
  margin-left: -8px;
}

@media only screen and (max-width: 992px) {
  .redeem-col2 {
    padding-left: 0px !important;
  }
  .t-amount {
    justify-content:start !important
  }
  
.redeem-col1 {
  padding-right: 0px !important;
}

.fw-reg-club-new {
  height: 40px !important;
}
}

@media only screen and (max-width: 600px) {

  .redeem-order-details {
    height: auto !important;
    width: 100%;
  }

  .club-cinepolis__dropdown .btn, .fw-reg-club-new button {
    font-size: 16px !important;
  }
  
 .black-tier svg, .silver-tier svg{
    width: 40px;
 }

 .black-tier, .silver-tier {
    height: 70px;
    border-radius: 16px;
 }

 .loyalty-register-static .percent-main {
    margin-top: -16px;
 }
 .loyalty-register-static .percent-main .percent-num{
    height: 65px;
}
.loyalty-register-static .register-now {
  font-size: 13px !important;
  width: 170px;
}

.loyalty-register-static .club-card-silver {
  left: 80px !important;
}

  .loyalty-register-static .display-6 {
    font-size: 20px;
  }
  .loyalty-register-static .points-generated {
    font-size: 18px;
  }
  .loyalty-register-static .fs-sm-16 {
    font-size: 16px;
  }
  .loyalty-register-static .fs-sm-18 {
    font-size: 18px;
  }
  .loyalty-register-static .fs-sm-14 {
    font-size: 14px;
  }
  .loyalty-register-static .fs-sm-13 {
    font-size: 13px;
  }
  .loyalty-register-static .fs-sm-12 {
    font-size: 12px;
  }


 .loyalty-register-static .club-card-image img {
    width: 295px;
  }

 .club-card-image img {
    width: 220px;
  }

  .black-tier-percent {
    font-size: 20px;
  }

  .black-tier-txt {
    font-size: 14px;
  }

  .club-cinepolis-about-main {
    padding: 10px 5px 10px 5px;
  }
  .loy-tier-img {
    gap: 8px !important;
  }

  .club-cinepolis-about-main-profile {
    padding: 10px 20px 10px 20px;
  }

  .proceed-review-order-btn {
    color: #7d7d7d;
  }

  .tnc-text-white {
    color: #7d7d7d;
  }

  .tnc-text-white:hover {
    color: #7d7d7d;
  }

  .club-info-background {
    width: 100%;
    padding: 20px;
  }

  .club-info-main-txt, .club-info-main-txt-s {
    font-size: 18px;
  }

  .club-info-card-details {
    font-size: 12px;
    margin-top: 25px;
  }

  .club-info-tier-details {
    margin-top: 25px;
  }

  .club-info-tier-details .tier{
    font-size: 14px;
  }

  .club-info-tier-details .earned, .info-text-small{
    font-size: 10px;
  }

  .club-info-tier-details .points, .club-info-tier-details .points-s{
    font-size: 13px;
  }

  .club-info-image {
    width: 30px;
  }

  
  .club-member-label {
    padding: 6px 0px 6px;
    font-size: 8px;
  }

  .club-member-text {
    padding: 10px 15px 10px;
    font-size: 8px;
  }

  .f12{
    font-size: 12px;
  }
  
  .f14{
    font-size: 14px;
  }

  .text-grey-club {
    line-height: 1.5;
  }

  .text-grey-club img {
    width: 20px;
  }

  .order-detail-redeem-mobile {
    padding: 15px 30px 80px 30px;
  }

  .input-white {
    background-color: #ffffff !important;
  }

  .club-cinepolis__dropdown .dropdown-menu {
    background-color: var(--dark-1);
    width: 118px !important;
  }

  .loyalty-register-static .black-tier p, .loyalty-register-static .silver-tier p{
    font-size: 12px !important;
  }
  .loyalty-register-static .percent-main {
    margin-top: -6px;
  }
  .loyalty-register-static .percent-main .percent-num{
    height: 40px;
    font-size: 32px !important;
  }
  

}

@media only screen and (max-width: 390px) {
  .black-tier svg, .silver-tier svg{
     width: 40px;
  }

  .loyalty-register-static .club-card-image img {
    width: 250px;
  }
 
  .club-card-image img {
     width: 200px;
   }
  .loyalty-register-static .black-tier p, .loyalty-register-static .silver-tier p{
    font-size: 12px !important;
  }
  .loyalty-register-static .percent-main {
    margin-top: -6px;
  }
  .loyalty-register-static .percent-main .percent-num{
    height: 40px;
    font-size: 32px !important;
  }
 }

 @media only screen and (max-width: 360px) {
  .loyalty-register-static .black-tier , .loyalty-register-static .silver-tier {
    padding: 0px 12px;
  }

  .loy-tier-img {
    gap: 4px !important;
  }

  .loyalty-register-static .black-tier, .loyalty-register-static .silver-tier {
    height: 60px;
  }

  .loyalty-register-static .club-card-image img {
    width: 225px;
  }

 }


 /* Added by Deepak */
.order-details-main{
  background-color: #000;
  color: #fff;
  padding: 5px 30px 5px 10px;
  border-radius: 8px;
  font-size: 21px;
}
.disabled-button {
  cursor: not-allowed;
}
.color-dark-gray {
  color: #373737; 
}
.ptr::placeholder {
  color: #000;
  font-size: 12px;
}
