:root {
  --club-1: #edd376;
  --club-2: #e2b950;
  --dark-1: #151515;
  --dark-2: #0f0f0f;
  --dark-3: #222222;
  --light-1: #cccccc;
}

/* Club Cinepolis Fonts */
@font-face {
  font-family: "clubBold";
  src: url("../fonts/TYPETYPE-TT NORMS MEDIUM.OTF");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "clubExtraBold";
  src: url("../fonts/TYPETYPE-TT NORMS EXTRABOLD.OTF");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "clubMedium";
  src: url("../fonts/TYPETYPE-TT NORMS MEDIUM.OTF");
  font-style: normal;
  font-display: swap;
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Club Cinepolis Register*/
.club-cinepolis-register {
  /* background-image: url("../pictures/club-cinepolis/background.png"); */
  background-position: center;
  background-size: 100%;
  font-family: "clubMedium";
}

.club-cinepolis-register > .container {
  background-color: black;
}

.club-cinepolis-register__join__banner > img {
  width: 100%;
}

.club-cinepolis-register__headline {
  font-size: clamp(20px, 3vw, 36px);
  text-align: center;
  color: var(--club-1);
  margin: clamp(16px, 2.5vw, 24px) 0;
  /* font-weight: bold; */
  font-family: "clubExtraBold";
}

/* Club Cinepolis Input */

.club-cinepolis__input {
  margin-bottom: 16px;
  width: 100%;
}

.club-cinepolis__input label {
  width: 100%;
  position: relative;
}

.club-cinepolis__input label > img {
  position: absolute;
  bottom: 14px;
  left: 8px;
  width: 30px;
}

.club-cinepolis__input input {
  outline: none;
  border: none;
  height: 45px;
  background-color: transparent;
  color: white;
  width: 100%;
  border-bottom: 2px solid grey;
  display: flex;
  align-items: center;
  padding: 0 16px 0 48px;
  font-size: 16px;
  /* font-weight: normal; */
  text-align: right;
}
.club-cinepolis__input1 input {
  outline: none;
  border: none;
  height: 45px;
  background-color: transparent;
  color: white;
  width: 100%;
  border-bottom: 2px solid grey;
  display: flex;
  align-items: center;
  /* padding: 0 16px 0 48px; */
  font-size: 16px;
  /* font-weight: normal; */
  text-align: left;
}

.club-cinepolis__input input[type="date"] {
  padding-left: 16px;
}

.club-cinepolis__input input[type="checkbox"],
.club-cinepolis__input input[type="radio"] {
  height: 0;
  width: 0;
}

.club-cinepolis__input__label {
  font-size: 14px;
}

.club-cinepolis__input.checkmark-span .club-cinepolis__input__label {
  margin-right: 32px !important;
}

.club-cinepolis__input.checkmark-span label > input ~ span {
  position: absolute;
  top: 4px;
  cursor: pointer;
  border: 2px solid var(--club-1);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  color: var(--club-1);
}

.club-cinepolis__input.checkmark-span label > input ~ span::after {
  content: url(../pictures/club-cinepolis-new/check.svg);
  position: absolute;
  top: -6px;
  left: 0;
  transform: scale(0);
  transition: transform 0.25s;
}

.club-cinepolis__input.checkmark-span label > input[type="radio"] ~ span::after{
content: "";
    width: 70%;
    height: 70%;
    background: var(--club-2);
    top: 2px;
    left: 2px;
    border-radius: 50%;
}

.club-cinepolis__input.checkmark-span label > input:checked ~ span::after {
  transform: scale(1);
}

.club-cinepolis__input .club-cinepolis__input__error{
  color: var(--danger);
  font-size:12px;
  text-align: right;
    margin-right: 8px !important;
}

/* For Customizing the date type input */
/* .club-cinepolis__input input::-webkit-datetime-edit {
  padding: 1em;
}
.club-cinepolis__input input::-webkit-datetime-edit-fields-wrapper {
  background: silver;
}
.club-cinepolis__input input::-webkit-datetime-edit-text {
  color: red;
  padding: 0 0.3em;
}
.club-cinepolis__input input::-webkit-datetime-edit-month-field {
  color: blue;
}
.club-cinepolis__input input::-webkit-datetime-edit-day-field {
  color: green;
}
.club-cinepolis__input input::-webkit-datetime-edit-year-field {
  color: purple;
}
.club-cinepolis__input input::-webkit-inner-spin-button {
  display: none;
} */
.club-cinepolis__input input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.club-cinepolis__input a {
  color: var(--club-1);
}

.club-cinepolis__input a:hover,
.club-cinepolis__input a:active,
.club-cinepolis__input a:visited,
.club-cinepolis__input a:focus {
  color: var(--club-2);
}

/* Club Cinepolis Dropdown */

.club-cinepolis__dropdown {
  margin-bottom: 16px;
  font-family: "clubMedium";
}

.club-cinepolis__dropdown.country__code {
  padding-left: 8px;
  margin-left: 8px;
  border-left: 1px solid white;
}

.club-cinepolis__dropdown .btn {
  border: none !important;
  background-color: transparent !important;
  border-bottom: 2px solid grey !important;
  padding-right: 40px;
  width: 100% !important;
  height: 45px;
  font-family: "clubMedium" !important;
}

.club-cinepolis__dropdown .dropdown-menu {
  background-color: var(--dark-1);
  width: 100% !important;
}

.club-cinepolis__dropdown .dropdown-item {
  color: white;
  background-color: transparent;
  font-family: "clubMedium" !important;
}

.club-cinepolis__dropdown .dropdown-item:hover,
.club-cinepolis__dropdown .dropdown-item:active,
.club-cinepolis__dropdown .dropdown-item:focus {
  background-color: white;
  color: var(--dark-1);
}

.club-cinepolis__dropdown__item {
  display: flex;
  align-items: center;
}

.club-cinepolis__dropdown__item > img {
  width: 32px;
  margin-left: 8px;
}

/* Club Cinepolis Button */
.club-cinepolis__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-weight: bold; */
  font-family: "clubBold";
  font-size: 16px;
  height: 45px;
  padding: 3px 16px 0;
  border-radius: 8px;
  background-color: var(--club-1);
  color: black;
  outline: none;
  border: 1px solid transparent;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s,
    transform 0.5s;
}

.club-cinepolis__btn:hover,
.club-cinepolis__btn:active {
  background-color: black;
  color: var(--club-1);
  border-color: var(--club-1);
}

.club-cinepolis__btn:focus {
  outline: none;
}

.club-cinepolis__btn:active {
  transform: scale(0.9);
}

.club-cinepolis__btn.disabled {
  cursor: default;
  pointer-events: none;
  color: grey;
  background-color: var(--dark-1);
  border-color: grey;
}

.club-cinepolis__btn.loading {
  cursor: default;
  pointer-events: none;
}

.club-cinepolis__btn.loading::after {
  content: "";
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: 2px solid black;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear;
}

/* Club Cinepolis Profile */

.club-cinepolis-profile {
  font-family: "clubMedium";
}

.club-cinepolis-profile .club-cinepolis-profile__headline {
  color: var(--club-1);
  font-size: clamp(24px, 3vw, 36px);
  text-align: center;
  /* font-weight: bold; */
  font-family: "clubExtraBold";
  margin-bottom: 24px;
}

.club-cinepolis-profile .club-cinepolis-profile__synopsis {
  font-size: clamp(16px, 2vw, 20px);
}

.club-cinepolis-profile .club-cinepolis-profile__sub-headline {
  font-size: clamp(18px, 2.5vw, 24px);
  /* font-weight: bold; */
  font-family: "clubBold";
}

/* Club Cinepolis Card */
.club-cinepolis-profile .club-cinepolis-card {
  position: relative;
  font-size: clamp(12px, 1.5vw, 16px);
}

.club-cinepolis-profile .club-cinepolis-card__img {
  position: relative;
  width: 100%;
}

.club-cinepolis-profile .club-cinepolis-card__content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  padding: clamp(20px, 3vw, 32px);
  display: flex;
  align-items: center;
}

.club-cinepolis-card.tier__one {
  color: white;
}

.club-cinepolis-card.tier__two {
  color: black;
  /* font-weight: bold; */
  font-family: "clubBold";
}

.club-cinepolis-card .club-cinepolis-card__number,
.club-cinepolis-card .club-cinepolis-card__name,
.club-cinepolis-card .club-cinepolis-card__tier {
  /* font-weight: bold; */
  font-family: "clubBold";
  /* font-size: 18px; */
}

.club-cinepolis-card .club-cinepolis-card__content__title {
  font-family: "clubExtraBold";
}

/* Club Checkout */
.club-checkout {
  font-size: 16px;
  font-family: "clubMedium";
  margin-bottom: clamp(150px, 20vw, 200px);
}

.club-checkout .club-checkout__user__details > div > h2 {
  font-size: clamp(18px, 2vw, 24px);
  font-family: "clubBold";
}

.club-checkout .club-cinepolis__input input {
  background-color: white;
  color: black;
  border: none;
  border-radius: 8px;
}

.club-checkout .club-checkout__points__redemption > div > h1 {
  font-size: clamp(28px, 3vw, 36px);
  font-family: "clubExtraBold";
  color: var(--club-1);
  text-align: center;
}

.club-checkout .club-checkout__points__redemption__tier {
  border: 2px solid var(--club-1);
  border-radius: 8px;
  padding: 8px 16px;
  margin-left: 16px;
  text-align: center;
}

.club-checkout .club-checkout__points__redemption__tier,
.club-checkout .club-checkout__points__redemption__points {
  font-size: clamp(16px, 1.5vw, 18px);
}

.club-checkout .club-checkout__points__redemption__tier > h3 {
  font-size: clamp(20px, 2.25vw, 28px);
  margin-bottom: 0;
  text-align: center;
}

.club-checkout .club-checkout__points__redemption__points {
  text-align: center;
}

.club-checkout .club-checkout__points__redemption__points > h3 {
  font-size: clamp(24px, 2.5vw, 32px);
  font-family: "clubBold";
  margin-bottom: 0;
  text-align: center;
}

.club-checkout .club-checkout__accordion .card {
  background: transparent !important;
  color: white !important;
  margin-bottom: 16px;
}

.club-checkout .club-checkout__accordion .card-header {
  /* background: linear-gradient(var(--club-1), var(--club-2)) !important ; */
  background: var(--club-1) !important ;

  color: black !important;
  cursor: pointer;
  position: relative;
  border-radius: 8px 8px 0 0;
  font-family: "clubBold";
  font-size: clamp(18px, 2vw, 20px);
}

.club-checkout .club-checkout__accordion .card-header::after {
  content: url("../pictures/club-cinepolis-new/arrow-down-black.svg");
  position: absolute;
  left: 16px;
  transition: transform 0.25s;
}

.club-checkout .club-checkout__accordion .card-header.active::after {
  transform: rotate(180deg);
}

.club-checkout .club-checkout__movie_details {
  display: flex;
  align-items: flex-end;
}

.club-checkout .club-checkout__movie_details figure {
  width: 100px;
  margin-bottom: 0;
  margin-left: 16px;
}

.club-checkout .club-checkout__movie_details figure > img {
  width: 100%;
}

.club-checkout .club-checkout__movie_details h3 {
  font-size: clamp(18px, 2vw, 24px);
  font-family: "clubBold";
  margin-bottom: 8px;
}

.club-checkout .club-checkout__movie_details p:not(:last-child) {
  margin-bottom: 4px;
}

.club-checkout .club-checkout__order_details .ordered__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.club-checkout .club-checkout__order_details .ordered__item.__modifier {
  display: none;
  padding-right: 16px;
}

.club-checkout
  .club-checkout__order_details
  .__modifier_group.show
  .__modifier {
  display: flex;
}

.club-checkout .club-checkout__order_details > .ordered__item {
  border-bottom: 1px solid white;
  padding-bottom: 8px;
}

.club-checkout .club-checkout__order_details > .ordered__item:not(:last-child) {
  margin-bottom: 8px;
}

.club-checkout .club-checkout__order_details .ordered__item > span {
  font-size: clamp(14px, 1.5vw, 16px);
}

.club-checkout
  .club-checkout__order_details
  > .ordered__item:last-child
  > span {
  font-size: clamp(16px, 1.5vw, 18px);
}

.club-checkout
  .club-checkout__order_details
  .ordered__item
  > span:nth-child(2) {
  font-family: "clubBold";
  margin-right: 16px;
}

.club-checkout .club-checkout__order_details .ordered__item.__modifier > span {
  font-size: clamp(12px, 1.2vw, 14px);
}

.club-checkout .club-checkout__order_details .ordered__item > span > span {
  font-size: clamp(10px, 1vw, 12px);
}

.club-checkout
  .club-checkout__order_details
  .ordered__item
  img.modifier__toggle {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.25s;
}

.club-checkout
  .club-checkout__order_details
  .__modifier_group.show
  .modifier__toggle {
  transform: rotate(180deg) translateX(-16px);
}

.club-checkout .club-checkout__tnc {
  position: fixed;
  bottom: 0;
  z-index: 100;
  right: 0;
  width: 100%;
  padding: 16px;
  background-color: var(--dark-1);
}

.club-checkout .club-checkout__tnc > article {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.club-checkout .club_checkout_payment_method h6{
  font-size: clamp(18px, 2vw, 24px);
  font-weight: bold;
}

.club-checkout .club_checkout_payment_method .club-cinepolis__input__label{
  font-size: 18px;
}

.club-checkout .club_checkout_payment_method .club-cinepolis__input{
width: auto;
}

/* How Club Works */
.how_club_works {
  font-family: "clubMedium";
  text-align: center;
}

.how_club_works figure {
  margin-bottom: 0;
}

.how_club_works .hcw__section__one,
.how_club_works .hcw__section__three {
  background-color: var(--dark-2);
}

.how_club_works .hcw__section__two {
  background-color: var(--dark-3);
}

.how_club_works .hcw__section__one,
.how_club_works .hcw__section__two,
.how_club_works .hcw__section__three {
  padding: clamp(24px, 4vw, 48px) clamp(16px, 3vw, 32px);
}

.how_club_works .hcw__section__one > p,
.how_club_works .hcw__section__two > p {
  font-size: clamp(16px, 2vw, 20px);
  text-align: center;
}

.how_club_works .hcw__section__one > h2,
.how_club_works .hcw__section__one > h3 {
  font-size: clamp(28px, 3vw, 36px);
  text-align: center;
}

.how_club_works .hcw__section__one > h3 {
  font-family: "clubBold";
}

.how_club_works .hcw__section__one > h1 {
  font-size: clamp(36px, 4vw, 48px);
  font-family: "clubExtraBold";
  color: var(--club-1);
  text-align: center;
}

.how_club_works .hcw__section__two > h3 {
  font-size: clamp(28px, 3vw, 36px);
  font-family: "clubExtraBold";

  color: var(--club-1);
  text-align: center;
}

.how_club_works .hcw__section__two > h5 {
  font-size: clamp(20px, 2.2vw, 24px);
  font-family: "clubBold";
  margin-bottom: clamp(16px, 3vw, 32px);
  text-align: center;
}

.how_club_works .hcw__section__two > h4 {
  font-size: clamp(18px, 2vw, 22px);
  font-family: "clubBold";
  margin-top: clamp(16px, 3vw, 32px);
  margin-bottom: clamp(12px, 1.5vw, 16px);
  text-align: center;
}

.how_club_works .hcw__section__two > p {
  color: var(--light-1);
}

.how_club_works .hcw__section__two > h4:first-of-type {
  border-top: 2px dotted white;
  padding-top: clamp(16px, 3vw, 32px);
  font-family: "clubExtraBold";
  margin-bottom: clamp(16px, 2vw, 24px);
  margin-bottom: clamp(16px, 2vw, 24px);
}

.how_club_works .hic__feature > article {
  border: 2px solid var(--club-1);
  border-radius: 16px;
  padding: 20px;
  margin-bottom: clamp(16px, 2vw, 24px);
  text-align: center;
}

.how_club_works .hic__feature > article > h5,
.how_club_works .hic__feature > article > h6 {
  font-size: clamp(18px, 2vw, 22px);
  text-align: center;
}

.how_club_works .hic__feature > article > h5 {
  font-family: "clubBold";
}

.how_club_works .hic__feature > article img {
  width: clamp(100px, 40%, 220px);
}

.how_club_works .hcw__points_generated {
  display: flex;
  align-items: center;
  justify-content: center;
}

.how_club_works .hcw__points_generated.hcw__gold__tier > article {
  color: black;
  background-color: var(--club-1);
  border: 2px solid black;
}

.how_club_works .hcw__points_generated > article svg {
  margin-left: 16px;
  width: clamp(75px, 8vw, 100px);
}

/* .how_club_works .hcw__points_generated.hcw__gold__tier > article svg {
  fill: black;
} */

.how_club_works .hcw__points_generated.hcw__black__tier > article {
  color: white;
  background-color: var(--dark-2);
  border: 2px solid var(--club-1);
}

/* .how_club_works .hcw__points_generated.hcw__black__tier > article svg {
  fill: var(--club-1);
} */

.how_club_works .hcw__points_generated > article {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 16px;
}

.how_club_works .hcw__points_generated > article > h5 {
  font-size: clamp(22px, 2.25vw, 28px);
  font-family: "clubExtraBold";
  margin-bottom: 0;
}

.how_club_works .hcw__points_generated > h1 {
  font-size: clamp(48px, 8vw, 80px);
  font-family: "clubExtraBold";
  margin-right: 16px;
}

.how_club_works .hcw__section__three > h3 {
  font-size: clamp(20px, 2.25vw, 26px);
  font-family: "clubExtraBold";
  margin-bottom: 24px;
  color: var(--club-1);
  text-align: center;
}

.how_club_works .hcw__process h4 {
  font-size: clamp(18px, 2vw, 22px);
  font-family: "clubBold";
}

.how_club_works .hcw__process ol {
  text-align: right;
  padding-right: 16px;
}

.how_club_works .hcw__process li {
  color: grey;
}

/* Loyalty Otp Popup */
.loyalty-otp-popup .modal-dialog{
  max-width: 600px;
}

.loyalty-otp-popup .container-fluid.wrapper{
    background-color: var(--dark-2);
    border-radius: 12px;
    padding: clamp(16px, 4vw, 48px);
    text-align: center;
    font-family: "clubMedium";
}

.loyalty-otp-popup .container-fluid.wrapper h1{
font-family: "clubExtraBold";
font-size: clamp(18px, 2.5vw, 28px);
text-align: center;
}

.loyalty-otp-popup .container-fluid.wrapper h2{
  font-size: clamp(14px, 1.5vw, 16px);
  text-align: center;
}

.loyalty-otp-popup .container-fluid.wrapper  .club-cinepolis__input{
  width: min(100%, 400px);
  margin: 20px auto 16px;
}

.loyalty-otp-popup .container-fluid.wrapper  .club-cinepolis__input input{
  border: none;
    background-color: white;
  color: var(--dark-2);
  border-radius: 8px;
}

/* Media Queries */
@media (max-width: 767px) {
  .club-cinepolis-profile {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 5000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    padding: 0 15px;
    overflow-y: auto;
  }

  .club-cinepolis-register {
    /* background-image: url("../pictures/club-cinepolis/background-mobile.png"); */
    padding: 0 30px;
  }
}

@media (min-width: 767px) {
  .club-checkout .club-checkout__points__redemption {
    border-right: 2px solid var(--club-1);
  }
}

@media (min-width: 1200px) {
  .club-checkout .club-checkout__points__redemption {
    border-left: 2px solid var(--club-1);
  }
}
