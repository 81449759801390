
/****FNB PAGE***/

.fnb-cart-total hr {
  border-top: 1px solid var(--grey-2);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.fnb-cart-details {
  background: var(--grey-3);
  /* padding: 24px; */
  font-family: "Raleway-Bold";
  font-size: 12px;
}
.fnb-cart-details .row > div:not(:last-child) {
  border-right: 1px solid var(--grey-2);
}
.fnb-cart-details-last {
  display: flex;
  align-items: center;
  gap: 40px;
}
.fnb-cart-details-last .btn {
  font-family: "Raleway-Medium";
  font-size: 12px;
  padding: 5px 20px;
}
.fnb-qty-modal {
  font-family: "Raleway-Medium" !important;
}
.fnb-qty-modal .fnb-item-bg {
  background: var(--purple);
  height: 140px;
  position: relative;
}
.fnb-qty-modal .fnb-sub-item {
  display: grid;
  grid-template-columns: 80% 20%;
  /* border-bottom: 1px solid var(--grey-2); */
  padding-bottom: 6px;
  align-items: center;
}
.fnb-sub-item div:last-child {
  text-align: right
}
.fnb-item-qty {
  background: var(--grey-3);
    display: grid;
    grid-template-columns: 30% 40% 30%;
    font-size: 13px;
}
.fnb-item-qty .btn {
  padding: 0px;
}
.fnb-item-qty img {
  width: 15px;
}
.fnb-item-qty span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sm-desc {
  width: 70%;
}

.skip-proceed-tag {
  font-size: 1.2rem;
}

.fnb-modal-image {
  width: 100%;
}
.fnb-item-bg1 {
  background: none !important;
}



.reserved-seat-layout {
  /* filter: invert(70%); */
}

.fnb-cancel-btn {
  color: #FFF !important;
  /* border: 2px solid var(--purple) !important; */
  background-color: #FFF !important;
}

.fnb-skip-proceed-align {
  /* width: 86%; */
  margin: auto;
}

.summary-page-section {
  padding: 50px 75px 50px 75px;
}

.summary-page-main-text {
  font-size: 25px;
  width: 100%;
  padding: 20px 0px 20px 0px;
  color: #717171;
}

.summary-order-details {
  width: 100%;
  background-color: #E4E4E4;
  /* height: 100px; */
  display: flex;
  align-items: center;
  padding: 10px;
  color: #717171;
  font-weight: bold;
}

.summary-movie-div {
  width: 7%;
  height: 100%;
  background-color: rgb(94, 94, 94);
}

.summary-movie-desc {
  width: 28%;
  padding: 0px 20px 0px 20px
}

.summary-movie-screen {
  width: 22%;
  padding: 0px 20px 0px 20px;
  border-left: 1px solid gray;
}

.summary-movie-fnb {
  width: 14%;
  padding: 0px 20px 0px 20px;
  border-left: 1px solid gray;
  max-height: 100px;
  overflow-y: auto;
}

.summary-movie-payment {
  width: 25%;
  padding: 0px 20px 0px 20px;
  border-left: 1px solid rgb(187, 187, 187);
}

.summary-movie-qr {
  width: 8%;
  height: 100%;
  background-color: rgb(94, 94, 94);
}

.summary-movie-tax {
  /* background-color: rgb(255, 255, 255); */
  width: 20%;
  padding: 14px;
  color: #717171;
  font-weight: bold;
}

.your-order-food {
  font-size: 12px !important;
  margin-top: 21% !important;
  border-radius: 10px;
}
/****PAYMENT PAGE*****/

.payment-new-page .form-control {
  border-bottom: 1px solid rgba(160, 160, 160, 1);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  background-color:transparent;
  color: black;
  padding: 0px;
}
.payment-new-page .form-control::placeholder {
  color: black;
}
.payment-new-page .flex-1 label {
  color:rgba(160, 160, 160, 1);
  font-size: 14px;
  margin-bottom: 0px;
}
.payment-new-page .bg-section {
  background: rgba(245, 245, 248, 1);
  padding: 20px;
  /* height: 160px; */
}
@media (max-width: 450px) {
  .payment-new-page .bg-section {
    padding: 10px 10px 10px 10px;
  }
}


.payment-new-page .fs-13 {
  font-size: 13px;
  color: rgba(160, 160, 160, 1);
  margin-top: 12px !important;
}
.payment-new-page .fs-14 {
  font-size: 14px;
  color: rgba(160, 160, 160, 1);
}
.payment-new-page .grid-2 {
  font-size: 13px;
  color: rgba(160, 160, 160, 1);
  grid-template-columns: 73% 27%;
  display: grid;
  gap: 0px;
}
.payment-new-page .grid-3 {
  display: grid;
  grid-template-columns: 40% 55%;
  font-size: 13px;
  gap: 5px;
  color: rgba(160, 160, 160, 1);
}
.payment-new-page .grid-5 {
  display: grid;
  grid-template-columns: 20% 53% 22%;
  font-size: 12px;
  grid-gap: 8px;
  gap: 8px;
  color: rgba(160, 160, 160, 1);
}
@media (min-width: 1200px) {
  .payment-new-page .flex-1 {
    gap: 12px;
    display: grid;
    grid-template-columns: 30% 46% 20%;
  }
  .payment-new-page .bg-section {
    height: 192px;
  }
  .payment-new-page .grid-1 {
    display: grid;
    grid-template-columns: 22% 73%;
    gap: 10px;
  }
  .border-lg-top {
    border-top: 1px solid rgba(160, 160, 160, 1);
  }
}
.form-control:disabled, .form-control {
  background: transparent !important;
}
.text-blue {
  color: rgba(77, 121, 250, 1);

}
@media (max-width: 768px) {
  .payment-new-page .flex-1 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .border-sm-top {
    border-top: 1px solid rgba(160, 160, 160, 1);
  }
}
@media (max-width: 450px) {
  .payment-new-page .flex-1 {
    gap: 0px;
  }
}
.payment-new-page .lg-font {
  font-size: 18px;
  font-weight: bold;
  color: var(--purple);
}

.payment-page .grid-1 {
  display: grid;
  grid-template-columns: 45% 30% 30% 30% 30%;;
  font-size: 15px;
}
/* .payment-page .grid-1 > div:before {
    border-top: 1px solid #333;
    width: 100px;
    margin-top: 0;
    margin-bottom: 0;
    transform: rotate(90deg);
    display: block;
    content: '';
} */
.payment-page .grid-1 > div:not(:last-child) {
    border-right:  1px solid var(--grey-2);
}
.payment-page .grid-1 > div {
  display: flex;
  flex-direction: column;
  font-family: "Raleway-SemiBold";
  color: var(--grey);
  position: relative;

}
.payment-page .form-1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px !important;
}
.payment-page .lg-font {
  font-size: 30px;
  color: var(--purple);
}
.payment-page .flex-1 {
  display: flex;
  font-size: 15px;
  gap: 30px;
}
.payment-page .flex-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end
}
.payment-page .flex-3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 15px; 
}
.payment-page .grid-2 {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;
}
.payment-page .grid-4 {
    display: grid;
    grid-template-columns: 25% 25%;
    gap: 2%
}
.payment-page .grid-4 .form-check {
    border: 1px solid var(--grey);
    border-radius: 05px;
    height: 35px;
    font-size: 14px;
    padding: 05px 30px;
}
.payment-page .card-details .form-control{
   padding-left: 20px;
}
.payment-page .btn {
 font-size: 14px;
 padding: 05px 25px;
 width: 300px;
 margin-top: 15px;
}




.heading-text {
  display: flex;
  justify-content: space-between;
}

.center-text {
  display: flex;
  justify-content: center;
}

.text-bold {
  font-size: large;
  font-weight: 600;
}

.text-extrabold {
  font-weight: bold;
  color: #363636;
}

.text-small {
  font-size: medium;
  font-weight: 300;
}

.cardheading {
  font-size: large;
  font-weight: 600;
  color: var(--purple);
}



@media (min-width: 375px) {
  .hero-banner2 img {
    height: 200px !important;
  }
}

@media (min-width: 375px) {
  .cardheading {
    padding-top: 1rem !important;
  }
}

.password {
  float: right;
}

.vr {
  height: 25rem;
  color: #7d7d7d;
  border-right: 1px solid #333;
}
.form-control {
  /* border: 1px solid #7d7d7d; */
}

@media screen and (max-width: 376px) {
  div.vrline {
    display: none !important;
  }
}
.login-page {
  background: #fff;
}

@media screen and (max-width: 768px) {
  .fnb-cart-details-last {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: start;
  }
  .fnb-cart-details .row > div:not(:last-child) {
    border-right: 1px solid transparent !important;
   
  }
  .payment-page .grid-4 {
    grid-template-columns: 40% 40%;
  }
  .payment-card-section {
    background: #fff;
    padding: 12px;
    font-size: 12px;
    margin-top: 12px;
    /* position: static !important; */
  }
}
.color-black {
  color: black;
}
[dir="rtl"] .payment-new-page .bg-section {
  text-align: right !important;
}
[dir="rtl"] .seat-order-details {
  text-align: right !important;
}
[dir="rtl"] .fnb-order-details,[dir="rtl"] .fnb-mobile-modal-details, [dir="rtl"] .fnb-qty-modal {
  text-align: right !important;
}

[dir="rtl"] .fnb-sub-item input {
  margin-right: auto;
    display: block;
}