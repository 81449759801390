:root {
  --white: #fff;
  /* --purple: #867bbf !important; */
  /* --purple: rgb(130,52,207) !important; */
  --purple: #4728B5 !important;
  /* --purple: linear-gradient(rgb(71,129,255) ,rgb(103,88,229) ) !important; */ /*Finalized*/
  --light-grey: #ebebeb !important;
  --grey: #363636 !important;
  --grey-2: #7d7d7d !important;
  --grey-3: #e4e4e4 !important;
}
@font-face {
  font-family: "butler-bold";
  src: url("../fonts/Butler_Bold.otf");
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../fonts/KumbhSans-Regular.woff2") format("woff2"),
    url("../fonts/KumbhSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-Medium";
  src: url("../fonts/Raleway-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("../fonts/Raleway-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-Medium";
  src: url("../fonts/Raleway-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-SemiBold";
  src: url("../fonts/Raleway-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-Bold";
  src: url("../fonts/Raleway-Bold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../fonts/KumbhSans-Light.woff2") format("woff2"),
    url("../fonts/KumbhSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh-Bold";
  src: url("../fonts/KumbhSans-Bold.woff2") format("woff2"),
    url("../fonts/KumbhSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Colfax-Regular";
  src: url("../fonts/Colfax-Regular.otf");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tajawal-Regular";
  src: url("../fonts/Tajawal-Regular.ttf");
  font-style: normal;
  font-display: swap;
}
[dir="rtl"] body {
  font-family: "Tajawal-Regular" !important;
}
body {
  overflow-x: hidden;
  font-family: "Raleway-Medium", sans-serif !important;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  background: #ffffff !important;
  /* font-family: "Kumbh Sans",sans-serif !important; */
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
  margin: 0 !important;
  padding: 0 !important;
}

@media (min-width: 1200px) {
  .container-spacing {
    width: 90% !important;
  }
}
.section-bg {
  /* background: var(--light-grey); */
}
.time-left-section {
  background: var(--light-grey);
}
.bg-purple {
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229));
  color: #fff;
}
.btn-purple {
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  color: var(--white) !important;
  transition: background-color 1000s;
  /* mask: linear-gradient(to left, #000, #0000); */
}
.btn-purple:hover {
  /* background: transparent !important; */
  background: white !important;
  color: rgb(71,129,255) !important;
  border: 1px solid rgb(71,129,255) !important;
  font-weight: bold;
}
.btn-gray {
  background: var(--grey) !important;
  color: var(--white) !important;
}
.btn-outline-purple {
  color: var(--purple) !important;
  border-color: var(--purple) !important;
}

.text-purple {
  color: var(--purple);
}

.text-dark-grey {
  color: #151515;
}

.text-grey {
  color: var(--grey-2);
}
.fw-light {
  font-family: "Raleway-Regular";
}
.fw-medium {
  font-family: "Raleway-Medium";
}
.fw-bold {
  font-family: "Raleway-Bold" !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-m {
  font-size: 12px !important;
}
.cus-dropdown-1 .btn {
  background: transparent;
  border: 1px solid transparent;
  color: var(--grey);
  padding: 0;
  margin: 0;
}
.cus-dropdown-1 .dropdown-toggle::after {
  content: "";
  display: block;
  background-image: url("../pictures/back-arrow.svg");
  position: absolute;
  top: -3px;
  right: 0;
  background-size: 22px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  border: none !important;
  transform: rotate(-90deg);
}
.cus-dropdown-2 .btn {
  background: transparent !important;
  border: 1px solid #797979 !important;
  color: var(--grey) !important;
  padding: 0;
  margin: 0;
  height: 40px !important;
  font-size: 14px;
  padding-left: 10px;
}
.cus-dropdown-2 .dropdown-toggle::after {
  content: "";
  display: block;
  background-image: url("../pictures/back-arrow.svg");
  position: absolute;
  top: 0px;
  right: 0;
  background-size: 22px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  border: none !important;
  transform: rotate(-90deg);
}

/***TABS DESIGN***/
.custom-tabs-1 {
  gap: 40px;
  margin-bottom: 20px !important;
  border-bottom: 4px solid transparent !important;
}
.custom-tabs-1 a {
  cursor: pointer;
  -webkit-transition: color 0.2s, border-color 0.3s;
  -o-transition: color 0.2s, border-color 0.3s;
  transition: color 0.2s, border-color 0.3s;
  /* border-bottom: 4px solid transparent; */
  color: var(--grey);
  padding: 0 !important;
  font-size: 16px;
  border: 1px solid transparent !important;
}
.custom-tabs-1 a:hover {
  color: var(--purple);
}
.custom-tabs-1 a[aria-selected="true"] {
  font-weight: bold;
  border-bottom: 4px solid var(--purple) !important;
  color: var(--purple) !important;
  background: transparent !important;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}
@media (max-width: 768px) {
  .time-left-section {
    padding: 10px 0px;
  }
  .custom-tabs-1 {
    gap: 20px;
    white-space: nowrap;
    flex-wrap: nowrap !important;
    margin-bottom: 10px !important;
    border-bottom: 4px solid transparent !important;
    /* overflow: auto; */
  }
  .movie-list-tabs {
    gap: 0;
    justify-content: space-between;
  }
  .custom-tabs-1 a {
    font-size: 12px;
  }
}
/***CUSTOM MODAL DESIGN****/
.custom-modal-1.modal.show {
  background: rgba(235, 235, 235, 0.2) !important;
}
.custom-modal-1 .modal-content {
  background: white !important;
  padding: 30px;
}
.custom-modal-1 .modal-header {
  padding: 0;
  position: absolute;
  top: 0;
  right: 13px;
}
.custom-modal-1 .modal-header .close {
  padding: 0px;
  margin: 0px;
}
.custom-modal-1 .modal-header .close span {
  font-size: 40px;
}
.fnb-qty-modal .modal-content {
  padding: 0px !important;
}
/***SEAT ICONS***/
.cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 2px 2px;
  width: 22px;
  height: 22px;
}
.seats .cell {
  cursor: pointer;
  -webkit-transition: color 0.15s ease-in, background-color 0.3s ease-out;
  -o-transition: color 0.15s ease-in, background-color 0.3s ease-out;
  transition: color 0.15s ease-in, background-color 0.3s ease-out;
  background-image: url("../pictures/seat-icons/seat.png");
  background-position: center;
  background-size: 20px !important;
  background-repeat: no-repeat;
  color: transparent;
}
.wheel-chair {
  cursor: pointer;
  background-image: url("../pictures/seat-icons/wheelchair.svg");
  background-position: center;
  background-size: 22px;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: transparent;
  font-weight: bold;
  width: 30px;
  height: 30px;
}
.arm-chair {
  cursor: pointer;
  background-image: url("../pictures/seat-icons/armchair.svg");
  background-position: center;
  background-size: 22px;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
}
.single-bean {
  cursor: pointer;
  background-image: url("../pictures/seat-icons/single-bean.svg");
  background-position: center;
  background-size: 22px;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  width: 30px;
  height: 30px;
}
.lounger {
  cursor: pointer;
  width: 36px !important;
  height: 36px;
  background-image: url("../pictures/seat-icons/luxury-lounger.svg");
  background-position: center;
  background-size: 22px;
  background-repeat: no-repeat;
  /* margin: 1px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}
.recliner {
  cursor: pointer;
  width: 36px !important;
  height: 36px;
  background-image: url("../pictures/seat-icons/recliner.svg");
  background-position: center;
  background-size: 22px;
  background-repeat: no-repeat;
  /* margin: 1px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}
.sofa,
.double-bean {
  cursor: pointer;
  width: 80px !important;
  height: 40px;
  background-image: url("../pictures/seat-icons/doublebean.svg");
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  /* margin: 1px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}
.seats .cell:hover,
.seats .cell:active,
.seats .cell.active {
  background-image: url("../pictures/seat-icons/seat-selected.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.sofa:hover,
.sofa:active,
.sofa.active,
.double-bean:hover,
.double-bean:active, 
.double-bean.active {
  background-image: url("../pictures/seat-icons/doublebean-selected.png");
}
.lounger:hover,
.lounger:active {
  background-image: url("../pictures/seat-icons/luxury-lounger-selected.png");
}
.recliner:hover,
.recliner:active {
  background-image: url("../pictures/seat-icons/recliner-selected.png");
}
.wheel-chair:hover,
.wheel-chair:active,
.wheel-chair.active {
  background-image: url("../pictures/seat-icons/wheelchair-selected.png");
}
.arm-chair:hover,
.arm-chair:active,
.arm-chair.active {
  background-image: url("../pictures/seat-icons/armchair-selected.png");
}
.single-bean:hover,
.single-bean:active,
.single-bean.active {
  background-image: url("../pictures/seat-icons/single-bean-selected.png");
}
.seats .cell.empty {
  background-color: transparent;
  color: transparent;
  border: transparent;
  cursor: unset;
  background-image: none;
  visibility: hidden;
}
.seats .cell.reserved {
  cursor: not-allowed;
  background-image: url("../pictures/seat-icons/seat-occupied.png");
}

.seats .cell.reserved-mario {
  cursor: not-allowed;
  background-image: url("../pictures/seat-icons/NINJASOLD.gif");
}

.reserved-seat-layout {
  width: 25px !important;
  height: 23px !important;
}

#reserved-mario-active {
  background-image: url("../pictures/seat-icons/NINJASOLD.gif");
  background-size: 25px !important;
}

#reserved-mario-active-sofa {
  background-image: url("../pictures/seat-icons/NINJASOLD.gif"), url("../pictures/seat-icons/NINJASOLD.gif");
  background-position: 14px center, 38px center;
  background-repeat: no-repeat;
  background-size: 25px !important;
}

#reserve-seat-1 {
  background-image: url("../pictures/seat-icons/NINJASOLD.gif");
  background-size: 25px !important;
}

#reserve-seat-2 {
  background-image: url("../pictures/seat-icons/ninja-sold-random1.gif");
  background-size: 25px !important;
}

#reserve-seat-3 {
  background-image: url("../pictures/seat-icons/ninja-sold-random2.gif");
  background-size: 25px !important;
}

#reserve-seat-4 {
  background-image: url("../pictures/seat-icons/ninja-sold-random3.gif");
  background-size: 25px !important;
}

#reserved-mario-active-sofa-1 {
  background-image: url("../pictures/seat-icons/ninja-sold-random3.gif"), url("../pictures/seat-icons/ninja-sold-random3.gif");
  background-position: 14px center, 38px center;
  background-repeat: no-repeat;
  background-size: 25px !important;
}
#reserved-mario-active-sofa-2 {
  background-image: url("../pictures/seat-icons/ninja-sold-random1.gif"), url("../pictures/seat-icons/ninja-sold-random1.gif");
  background-position: 14px center, 38px center;
  background-repeat: no-repeat;
  background-size: 25px !important;
}
#reserved-mario-active-sofa-3 {
  background-image: url("../pictures/seat-icons/NINJASOLD.gif"), url("../pictures/seat-icons/NINJASOLD.gif");
  background-position: 14px center, 38px center;
  background-repeat: no-repeat;
  background-size: 25px !important;
}
#reserved-mario-active-sofa-4 {
  background-image: url("../pictures/seat-icons/ninja-sold-random1.gif"), url("../pictures/seat-icons/ninja-sold-random1.gif");
  background-position: 14px center, 38px center;
  background-repeat: no-repeat;
  background-size: 25px !important;
}

.sofa.reserved,
.double-bean.reserved {
  cursor: not-allowed;
  background-image: url("../pictures/seat-icons/doublebean-occupied.png");
}
.sofa.empty {
  background-color: transparent;
  color: transparent;
  border: transparent;
  cursor: unset;
  background-image: none;
  display: none !important;
}
.wheel-chair.reserved {
  cursor: not-allowed;
  background-image: url("../pictures/seat-icons/wheelchair-occupied.png");
}
.wheel-chair.empty {
  background-color: transparent;
  color: transparent;
  border: transparent;
  cursor: unset;
  background-image: none;
  display: none !important;
}

/***TIMELINE HEADER****/

.flow-timeline-flex {
  display: grid;
  grid-template-columns: repeat(6, 16%);
  align-items: center;
  justify-content: center;
  color: var(--grey-2);
}
.flow-timeline {
  position: relative;
  text-align: center;
  /* cursor: pointer; */
}
.flow-timeline:not(:last-child):after {
  content: "";
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 35%;
  left: 70px;
  border-top: 2px dashed var(--grey-2);
}
.flow-timeline .flow-timeline-text {
  font-size: 14px;
  margin-top: 05px;
  white-space: nowrap;
  color: var(--grey-2);
  font-family: "Raleway-SemiBold";
}
.flow-timeline-icon {
  border: 1px solid var(--grey);
  border-radius: 50%;
  display: inline-block;
  padding: 10px;
  position: relative;
  background: white;
  z-index: 1000;
}
.flow-timeline-icon svg {
  width: 30px;
  height: 30px;
}
.current-timeline .flow-timeline-icon,
.active-timeline .flow-timeline-icon {
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  border: 1px solid var(--purple);
}
.active-timeline .timeline-img-unactive {
  filter: none;
}
.current-timeline svg path,
.current-timeline svg polygon,
.active-timeline svg path,
.active-timeline svg polygon {
  fill: white;
}
.current-timeline .flow-timeline-text,
.active-timeline .flow-timeline-text {
  color: var(--grey-2);
  font-family: "Raleway-SemiBold";
}
.active-timeline:not(:last-child):after {
  border-top: 2px dashed #5E65ED;
}
.current-timeline:not(:last-child):after {
  border-top: 2px dashed #5E65ED;
  content: "";
  display: inline-block;
  width: 50%;
  position: absolute;
  top: 35%;
  left: 80px;
  /* border-image:   linear-gradient(to right, var(--purple) 60%, var(--grey) 50%) 2; */
}
.current-timeline::before {
  border-top: 2px dashed var(--grey-2);
  content: "";
  display: inline-block;
  width: 50%;
  position: absolute;
  top: 35%;
  right: -70px;
}

@media (max-width: 767px) {
  .flow-timeline-flex {
    grid-template-columns: repeat(6, 20%);
    overflow: auto;
    justify-content: flex-start;
    padding: 15px 0;
  }
  .flow-timeline-icon {
    padding: 05px;
  }
  /* .current-timeline::before {
    right: -13px;
  } */
  .flow-timeline:not(:last-child):after {
    /* left: 45px; */
    /* width: 45px; */
    left: 50px;
  }
  .flow-timeline .flow-timeline-text {
    font-size: 13px;
  }
}





.grid-dropdown .btn {
  width: 100%;
}

@media (max-width: 768px) {
  .grid-dropdown {
    display: grid;
    grid-template-columns: 80px 120px 120px;
  }
}



/***CUSTOM FOOTER***/
.footer-right-content {
  justify-content: flex-end;
}
.custom-footer-top .grid-dropdown {
  display: grid;
  grid-template-columns: 45% 45% 45%;
  grid-gap: 10px;
}





.custom-footer-top {
  background: var(--grey-3);
}
.custom-footer-top .footer-links-1 {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: 70px;
}
.custom-footer-top .footer-links-1 a {
  color: var(--grey-2);
}
.custom-footer-top .footer-links-1 a:hover {
  color: var(--purple);
  text-decoration: none;
}
.custom-footer-bottom {
  color: var(--grey-2);
}
.footer-social-icons {
  gap: 15px;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  color: var(--grey-2) !important;
}
.footer-social-icons a{
  color: var(--grey-2) !important;
  text-decoration: none !important;
}
.footer-social-icons a:hover{
  text-decoration: none;
  cursor: pointer;
  color: #4728B5 !important;
}

@media (max-width: 950px) {
  .custom-footer-top .footer-links-1 {
    gap: 15px;
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .mobile-margin-bottom {
    margin-bottom: 60px;
  }
  .custom-footer-top .footer-links-1 {
    gap: 10px;
    flex-wrap: wrap;
  }
}
.custom-mobile-navbar {
  background: white;
}
.custom-mobile-navbar .header-icons {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 20px;
}
.custom-mobile-navbar .header-icons svg {
  height: 20px;
  width: 20px;
}
.custom-mobile-navbar .header-icons svg path {
  fill: var(--grey);
}
.search-input-field {
  text-align: right;
}
.search-input-field a {
  /* color: white; */
  color: var(--grey);
}
.mobile-menu-floaters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  /* box-shadow: 5px 10px 20px rgb(40 111 219 / 16%); */
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.mobile-menu-floaters .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* border-radius: 0 0 20px 20px; */
  background-color: #fff;
  height: 60px;
}
.mobile-menu-floaters .menu svg {
  height: 25px;
}
.mobile-menu-floaters .menu svg path,
.mobile-menu-floaters .menu svg g {
  fill: var(--grey);
}

.menu .menu-item {
  border-top: 5px solid transparent;
  -webkit-transition: color 0.2s, border 0.3s;
  -o-transition: color 0.2s, border 0.3s;
  transition: color 0.2s, border 0.3s;
  border-top: 5px solid transparent;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.menu .menu-item:active,
.menu .menu-item.active {
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229));
}

.menu .menu-item.active svg g,
.menu .menu-item.active svg path {
  fill: white;
}
.menu .menu-item.active svg {
  filter:  brightness(0) invert(1) !important;
}
.menu .menu-item.active img {
  filter:  brightness(0) invert(1) !important;
}
.mobile-sidebar {
  background: var(--grey-3);
  position: fixed;
  width: 100%;
  z-index: 2000;
  height: 100%;
  padding: 20px 20px 0 20px;
  transition: 0.4s ease-in-out;
  right: -100%;
}
.mobile-sidebar.showbar {
  right: 0 !important;
}
.mobile-sidebar a {
  color: var(--grey);
  text-decoration: none;
  border-bottom: 1px solid var(--grey-2);
  display: block;
  padding: 6px 0;
  font-size: 16px;
  text-transform: capitalize;
}
.sidebar-footer-bottom {
  position: absolute;
  bottom: 130px;
  width: 100%;
  padding: 20px;
  right: 0;
  font-size: 15px;
}

/****CUSTOM ACCORDION 2******/
.custom-accordion-2 .card-header {
  background: var(--light-grey) !important;
  position: relative;
  padding: 0 15px !important;
  border-bottom: 1px solid var(--light-grey) !important;
  cursor: pointer;
}
.custom-accordion-2 .card-header::before {
  content: "";
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 30px;
  border-top: 1px solid var(--grey-2);
}
.custom-accordion-2 .card-header:after {
  width: 16px;
  height: 16px;
  position: relative;
  content: "";
  margin-left: auto;
  display: block;
  background-size: 100%;
  position: absolute;
  top: 08px;
  right: 0;
  background: url("../../assets/pictures/svgs/arrow-up.svg") 50% 50% no-repeat;
}
.custom-accordion-2 .card-header.hidden:after {
  transform: rotate(180deg);
}
.custom-accordion-2 .card-header.show:after {
  transform: rotate(90deg);
}
.custom-accordion-2 span {
  background-color: var(--light-grey);
  position: relative;
  font-size: 20px;
  padding: 0 10px 0px 10px;
}
@media (max-width: 450px) {
  .custom-accordion-2 span {
    font-size: 14px !important;
  }
  .text-no-voucher {
    font-size: 12px;
  }
  .seats .cell, .wheel-chair, .double-bean, .sofa {
    pointer-events: none;
  }
  .seats .cell.reserved-mario, #reserve-seat-1, #reserve-seat-2, #reserve-seat-3, #reserve-seat-4, #reserved-mario-active, #reserved-mario-active-sofa, #reserved-mario-active-sofa-1, #reserved-mario-active-sofa-2, #reserved-mario-active-sofa-3, #reserved-mario-active-sofa-4 {
    background-size: 17px !important;
  }
  .reserved-seat-layout {
    width: 17px !important;
    height: 17px !important;
  }
  #reserved-mario-active-sofa, #reserved-mario-active-sofa-1, #reserved-mario-active-sofa-2, #reserved-mario-active-sofa-3, #reserved-mario-active-sofa-4 {
    background-position: -1px center, 12px center;
  }

}
.custom-accordion-2 .card {
  background: var(--light-grey) !important;
  border: 1px solid var(--light-grey);
}
.custom-accordion-2 .card-body {
  padding: 15px 0 30px 30px;
}
.custom-accordion-3 {
  width: 100%;
}
.custom-accordion-3 .card {
  background: var(--light-grey) !important;
  margin-bottom: 10px !important;
  border: 1px solid var(--light-grey);
}
.custom-accordion-3 .card-header {
  background: #cccccc;
  color: var(--grey);
  border: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
  border-radius: 05px !important;
}
.custom-accordion-3 .card-header:after {
  width: 20px;
  height: 20px;
  position: relative;
  content: "";
  margin-left: auto;
  display: block;
  background-size: 100%;
  position: absolute;
  top: 16px;
  right: 7px;
  background: url("../pictures/slick-arrows.svg") 50% 50% no-repeat;
  background-size: 20px;
  transform: rotate(-90deg);
}
.custom-accordion-3 .card-header.hidden:before {
  width: 25px;
  height: 25px;
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  border-radius: 50%;
  position: absolute;
  content: "";
  display: block;
  right: 5px;
}
.custom-accordion-3 .card-header.show:after {
  transform: rotate(90deg);
}
.custom-accordion-3 .card-header.show {
  background: linear-gradient(rgb(71,129,255) ,rgb(103,88,229)) !important;
  color: #fff;
}
.custom-accordion-3 .card-body {
  border: 1px solid var(--light-grey);
  background: var(--light-grey) !important;
}

.modal-body-checkout-tnc .custom-accordion-3 .card-header.hidden:before {
  right: 3px;
}
.custom-form {
  border: 1px solid var(--grey) !important;
  color: var(--grey) !important;
  font-size: 14px;
  background: transparent !important;
  height: 35px;
}
.custom-form::placeholder {
  font-size: 14px;
  color: var(--grey);
}
.custom-form-1 {
  border: 1px solid #797979 !important;
  color: var(--grey) !important;
  font-size: 14px !important; 
  background: transparent !important;
  height: 40px !important;
}
.custom-text-area {
  min-height: 150px !important;
}

.mobile-dropdown-code {
  display: grid;
  grid-template-columns: 25% 72%;
  gap: 10px;
}

#root input#paymentbuttonclick{
  display: none !important;
}
.mobile-margin-bottom {
  min-height: 77vh;
}

.choose-city {
  font-family: "Raleway-Bold";
}

.custom-modal-city.modal.show {
  background: rgba(235, 235, 235, 0.2) !important;
  backdrop-filter: blur(5px);
}

.custom-modal-city .modal-content {
  background: white !important;
  z-index: 999 !important;
  padding: 0px;
  width: 432px;
  height: 264px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* Center horizontally */
  border-radius: 12px !important
    /* Add rounded corners */
}

@media (max-width: 767px) {
  .custom-modal-city.modal.show {
    backdrop-filter: blur(5px);
    /* Adjust blur effect for smaller screens */
  }

  .custom-modal-city .modal-content {
    width: 90%;
    height: auto;
    border-radius: 12px !important;
  }
}

@media (max-width: 575px) {
  .custom-modal-city .modal-content {
    width: 100%;
    margin: 0;
    height: auto;
    border-radius: 12px !important;

  }
}