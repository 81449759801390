.triangle {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust the distance from the right side of the button */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid blue;
    /* Match the button's background color */
}

.slick-track{
    display: flex !important;
}